import React from "react";

import { useTranslation } from "react-i18next";
import logo from "../../images/logo_bilbao_firma.png";

// Import Styles
import "./LogoNavbarLogin.css";

function LogoNavbarLogin() {
  const [t, i18n] = useTranslation();
  return (
    <div className="LogoNavbarLogin">
      <img src={logo} title="BBK Logo" alt="BBK Logo" width="300" />
      <h1>{t("navbar.Itinerary")}</h1>
      <p>{t("navbar.Register")}</p>
    </div>
  );
}

export default LogoNavbarLogin;
