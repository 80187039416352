import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Home from "./Home";
import ArtworkDetail from "./gallery/ArtworkDetail";
import Login from "./functionality/Login";
import Signup from "./functionality/Signup";
import Reset from "./functionality/Reset";
import ResetConfirm from "./functionality/ResetConfirm";
import UserInformation from "./functionality/UserInformation";
import ArtworkInfo from "./gallery/ArtworkInfo";
import ArtworkCanvas from "./gallery/ArtworkCanvas";
import ArtworkComposition from "./gallery/ArtworkComposition";
import UserLikes from "./functionality/UserLikes";
import ArtworkAddCollection from "./gallery/ArtworkAddCollection";
import UserCollections from "./functionality/UserCollections";
import Collection from "./gallery/Collection";
import CollectionView from "./gallery/CollectionView";
import CreateCollection from "./gallery/CreateCollection";
import MuseumMap from "./gallery/MuseumMap";
import TermsAndConditions from "./legal/TermsAndConditions";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import Footer from "./functionality/Footer";
import Legal from "./legal/Legal";
import WebMap from "./legal/WebMap";
import WebAccessibility from "./legal/WebAccessibility";
import Cookies from "./legal/Cookies";

const MyArtworkInfo = withTranslation()(ArtworkInfo);
const MyArtworkComposition = withTranslation()(ArtworkComposition);
const MyArtworkCanvas = withTranslation()(ArtworkCanvas);
const MyArtworkDetail = withTranslation()(ArtworkDetail);
const MyMuseumMap = withTranslation()(MuseumMap);
const MyPrivacyPolicy = withTranslation()(PrivacyPolicy);
const MyTermsAndConditions = withTranslation()(TermsAndConditions);
const MyLegal = withTranslation()(Legal);
const MyWebMap = withTranslation()(WebMap);
const MyCookies = withTranslation()(Cookies);
const MyWebAccessibility = withTranslation()(WebAccessibility);
const MyUserInformation = withTranslation()(UserInformation);
const MyCreateCollection = withTranslation()(CreateCollection);
const MyCollectionView = withTranslation()(CollectionView);
const MyUserCollections = withTranslation()(UserCollections);
const MyUserLikes = withTranslation()(UserLikes);
const MyCollection = withTranslation()(Collection);

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="App-navigation">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/reset" component={Reset} />
            <Route
              path="/rest-auth/password/reset/confirm/:uid/:token/"
              component={ResetConfirm}
            />
            <Route path="/obras/info/:artwork" component={MyArtworkInfo} />
            <Route
              path="/obras/composicion/:artwork"
              component={MyArtworkComposition}
            />
            <Route path="/obras/canvas/:artwork" component={MyArtworkCanvas} />
            <Route path="/obras/:artwork" component={MyArtworkDetail} />
            <Route path="/user-information" component={MyUserInformation} />
            <Route path="/user-likes" component={MyUserLikes} />
            <Route path="/user-collections" component={MyUserCollections} />
            <Route
              path="/artworkAddCollection/:artworkId"
              component={ArtworkAddCollection}
            />
            <Route
              path="/collectionDetail/:collectionId"
              component={MyCollectionView}
            />
            <Route
              path="/collectionEdit/:collectionId"
              component={MyCollection}
            />
            <Route
              path="/createCollection/:artworkId"
              component={MyCreateCollection}
            />
            <Route path="/museum-map/:artwork" component={MyMuseumMap} />
            <Route path="/privacy-policy" component={MyPrivacyPolicy} />
            <Route
              path="/terms-and-conditions"
              component={MyTermsAndConditions}
            />
            <Route path="/legal" component={MyLegal} />
            <Route path="/web-map" component={MyWebMap} />
            <Route path="/cookies" component={MyCookies} />
            <Route path="/web-accessibility" component={MyWebAccessibility} />
            <Route path="/" component={Home} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;

//React Live Reload

if (module.hot) {
  module.hot.accept();
}
