class AuthService {
  logout() {
    localStorage.removeItem("token");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  refreshCurrentUser(data) {
    localStorage.setItem("user", JSON.stringify(data));
  }
}

export default new AuthService();
