import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import Footer from "../functionality/Footer";
import Navbar from "../functionality/Navbar";

import { Link } from "react-router-dom";

import "./Legal.css";

function Cookies(props) {
  const { t, i18n } = props;

  return (
    <div className="TermsAndConditions">
      <Navbar />
      <div className="legal-box">
        <h1>{t("Cookies.Cookies")}</h1>
        <h2>{t("Cookies.Use")}</h2>
        <p>
          {t("Cookies.Consent")}: {t("Cookies.Consent text")}
        </p>
        <p>
          {t("Cookies.Refuse")}: {t("Cookies.Refuse text")}
        </p>
        <p>
          {t("Cookies.Configuration")}: {t("Cookies.Configuration text")}
        </p>
        <p>{t("Cookies.Configuration text 2")}</p>
        <p>{t("Cookies.Configuration text 3")}</p>
        <h2>{t("Cookies.What")}</h2>
        <p>{t("Cookies.What text")}</p>
        <h2>{t("Cookies.Why")}</h2>
        <p>{t("Cookies.Why text")}</p>
        <h2>{t("Cookies.Types")}</h2>
        <ul>
          <li>
            <p>
              {t("Cookies.Own")}: {t("Cookies.Own text")}
            </p>
          </li>
          <li>
            <p>
              {t("Cookies.Third")}: {t("Cookies.Third text")}
            </p>
          </li>
          <li>
            <p>
              {t("Cookies.Session")}: {t("Cookies.Own text")}
            </p>
          </li>
          <li>
            <p>
              {t("Cookies.Persistent")}: {t("Cookies.Persistent text")}
            </p>
          </li>
          <li>
            <p>
              {t("Cookies.Analysis")}: {t("Cookies.Analysis text")}
            </p>
          </li>
        </ul>
        <h2>{t("Cookies.Cookie title")}</h2>
        <h2>{t("Cookies.Arteder")}</h2>
        <p>{t("Cookies.Arteder text")}</p>
        <h2>{t("Cookies.Yandex")}</h2>
        <p>{t("Cookies.Yandex text")}</p>
        <h2>{t("Cookies.Google")}</h2>
        <p>{t("Cookies.Google text")}</p>
        <h2>{t("Cookies.Social")}</h2>
        <p>{t("Cookies.Social text")}</p>
        <ul>
          <li>
            <p>
              {t("Cookies.Facebook")}
              <a href="https://www.facebook.com/policies/cookies/">
                {t("Cookies.Facebook link")}
              </a>
            </p>
          </li>
          <li>
            <p>
              {t("Cookies.Instagram")}
              <a href="https://help.instagram.com/1896641480634370?ref=ig">
                {t("Cookies.Instagram link")}
              </a>
            </p>
          </li>
        </ul>
        <p>{t("Cookies.Privacy")}</p>
        <h2>{t("Cookies.Disabling")}</h2>
        <p>{t("Cookies.Disabling text")}</p>
        <p>{t("Cookies.Disabling text 2")}</p>
        <ul>
          <li>
            <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es">
              <p>{t("Cookies.For")} Firefox</p>
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
              <p>{t("Cookies.For")} Safari</p>
            </a>
          </li>
          <li>
            <a href="https://support.google.com/chrome/answer/95647?hl=es">
              <p>{t("Cookies.For")} Chrome</p>
            </a>
          </li>
          <li>
            <a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">
              <p>{t("Cookies.For")} Internet Explorer</p>
            </a>
          </li>
          <li>
            <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
              <p>{t("Cookies.For")} Opera</p>
            </a>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Cookies;
