import swal from "sweetalert";

const FacebookLoginAPI = async access_token => {
  return fetch(
    "https://backend.itinerariosonline.bilbaomuseoa.eus/dj-rest-auth/facebook/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ access_token }),
    }
  )
    .then(resp => resp.json())
    .then(data => {
      if (data.access_token) {
        localStorage.setItem("id_token", data.access_token);
        localStorage.setItem("refresh", data.refresh_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        window.location.replace("/");
      } else {
        swal("failed authentication with facebook");
      }
    });
};

export default FacebookLoginAPI;
