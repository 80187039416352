import React from "react";
import { Link } from "react-router-dom";
import LanguageSelector from "../translation/LanguageSelector";
import perfil from "../../images/perfil.svg";

import "./Navbar.css";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.profileImage = this.profileImage.bind(this);
    this.state = {
      profile_image: null,
      image: null,
    };
  }

  profileImage() {
    if (this.props.user) {
      var profile_image = null;
      if (this.props.user.profile_image) {
        profile_image = this.props.user.profile_image;
      } else {
        profile_image = perfil;
      }

      return (
        <Link className="login-link" to="/user-information">
          <img id="profile-image" src={profile_image} />
        </Link>
      );
    } else {
      return (
        <Link className="login-link" to="/login">
          <img src={perfil} />
        </Link>
      );
    }
  }

  render() {
    const { positionCss = "bottom" } = this.props;
    return (
      <div className={ this.props.menu ? `` : `Navbar ${positionCss}`}>
        <LanguageSelector handler={this.props.handler } menu={this.props.menu} />
        {this.profileImage()}
      </div>
    );

  }
}

export default Navbar;
