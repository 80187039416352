import React, { useState } from "react";
import i18n from "./i18n";

import "./LanguageSelector.css";

function LanguageSelector(props) {
  const [language, setLanguage] = useState("");

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    //window.location.reload(false);
  };

  return (

    <div className={props.menu ? "LanguageSelector sideBarLanguageSelector" : "LanguageSelector"}>
      <button
        onClick={() => {
          changeLanguage("eus");
          props.handler && props.handler("eus");
        }}
      >
        Eu
      </button>
      <button
        onClick={() => {
          changeLanguage("es");
          props.handler && props.handler("es");
        }}
      >
        Es
      </button>
      <button
        onClick={() => {
          changeLanguage("en");
          props.handler && props.handler("en");
        }}
      >
        En
      </button>
    </div>
  );
}

export default LanguageSelector;
