import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import Footer from "../functionality/Footer";
import Navbar from "../functionality/Navbar";

import { Link } from "react-router-dom";

import "./Legal.css";

function WebMap(props) {
  const { t, i18n } = props;

  return (
    <div className="TermsAndConditions">
      <Navbar />
      <div className="legal-box">
        <Link to="/">
          <h4>{t("Map.Standars")}</h4>
        </Link>
        ;
      </div>
      <Footer />
    </div>
  );
}

export default WebMap;
