export const getArtworkWithUrlFriendlyFormat = (id, name) => {
  let url = id.toString() + "-";
  let newName = name
    .normalize("NFD")
    .replace(/[\u0300-\u036f,()]/g, "")
    .trim()
    .toLowerCase();
  return url + newName.replace(/\s/g, "-");
};

export const getURLSearchParamsValues = (search, entries) => {
  const searchParams = new URLSearchParams(search);
  return entries.map(entry => searchParams.get(entry));
};

export const getErrorList = (error, defaultMessage = "Error") => {
  const apiErrors = Object.keys(error?.response?.data?.errors).length || error;
  return apiErrors
    ? Object.values(error.response.data.errors).flat().join("\n")
    : defaultMessage;
};
