import React from "react";
import { Link } from "react-router-dom";
import edit from "../../images/action/edit_collection.svg";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
import CollectionService from "../services/collection.service";
import ImageGrid from "./ImageGrid";
import "./CollectionListMin.css";

class CollectionListMin extends React.Component {
  constructor(props) {
    super(props);

    // this.update_token = this.update_token.bind(this);
    this.addArtwork = this.addArtwork.bind(this);
    this.renderList = this.renderList.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
      error: null,
      token: "",
      // resp: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
    // this.setState({ refresh: this.update_token() });
  }

  // async update_token() {
  //   const body = {
  //     refresh: localStorage.getItem("refresh"),
  //   };
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   };
  //   await axios
  //     .post(
  //       "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
  //       body,
  //       config
  //     )
  //     .then(res => {
  //       this.setState({ token: res.data.access });
  //     })
  //     .catch(err => {
  //       console.log(
  //         "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
  //       );
  //       // document.location.replace("https://itinerariosonline.museobilbao.com/login");
  //     });
  // }

  async addArtwork(id, title) {
    // await this.update_token();
    const refresh = localStorage.getItem("refresh");

    if (refresh) {
      const artw = [];
      artw[0] = this.props.art;

      // const body = {
      //   title: title,
      //   artworks: artw,
      // };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + refresh,
        },
      };
      await axios
        .post(
          `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections/${id}/artworks`,
          { artwork_id: this.props.art },
          config
        )
        .then(res => {
          // this.setState({ resp: res.data });
          swal("Obra agregada a su colección");
          document.getElementById("collections-overlay").style.visibility =
            "hidden";
        })
        .catch(err => {
          swal(
            "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
          );
          //document.location.replace("https://itinerariosonline.museobilbao.com/login");
        });
    }
  }

  renderList() {
    const { error, currentUser, collection } = this.state;
    if (currentUser) {
      return collection.galleries.map(
        ({ id, title, description, artworks }) => {
          if (collection) {
            const images = artworks.map(({ thumbnail }) => thumbnail);
            return (
              <div
                key={id}
                id={id}
                className="Collection-list"
                onClick={() => this.addArtwork(id, title)}
              >
                <ImageGrid images={images} size="min" />
                <div className="min-text">
                  <h2 id="h2">{title}</h2>
                  <p id="p">{description}</p>
                </div>
              </div>
            );
          } else {
            return true;
          }
        }
      );
    } else {
      console.log("Debe logearse o registrarse para ver este contenido");
    }
  }

  render() {
    return <div className="full-width">{this.renderList()}</div>;
  }
}

export default CollectionListMin;
