import React from "react";
import axios from "axios";
import FilterList from "./functionality/FilterList";
import LogoNavbar from "./functionality/LogoNavbar";
import GalleryList from "./gallery/GalleryList";
import Footer from "./functionality/Footer";
import AuthService from "./services/auth.service";
import Navbar from "./functionality/Navbar";
import CollectionService from "./services/collection.service";

import videoMuseo from "../video/museo.mp4";

import i18n from "./translation/i18n";

import "./Home.css";
import { Link } from "react-router-dom";

//let API = 'https://backend.itinerariosonline.bilbaomuseoa.eus/labels/';

let API =
  "https://backend.itinerariosonline.museobilbao.com:8081/api/collections";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);

    this.state = {
      labels: [],
      galleries: [],
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
      isLogged: false,
      isLoding: false,
      error: null,
      refresh: null,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true }); // Loading... Spinner condition
    this.setState({ collection: CollectionService.getCurrentCollection() });

    window.scrollTo(0, 0);

    // API Request Labels
    axios
      .get(API)
      .then(response =>
        this.setState({
          labels: response.data.labels,
          isLoading: false,
          galleries: response.data.galleries,
        })
      )
      .catch(error => this.setState({ error, isLoading: false }));

    this.setState({ refresh: this.update_token() });
  }

  handler = value => {
    this.setState({
      language: value,
    });
  };

  async update_token() {
    const refresh = localStorage.getItem("refresh");
    // const body = {
    //   refresh: localStorage.getItem("refresh"),
    // };
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //     const config2 = {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + res.data.access,
    //       },
    //     };

    //     axios
    //       .get(
    //         "https://backend.itinerariosonline.bilbaomuseoa.eus/collections/",
    //         config2
    //       )
    //       .then(res => {
    //         CollectionService.refreshCurrentCollection(res.data);
    //         this.setState({ collection: res.data });
    //       })
    //       .catch(err => {
    //         alert("Ha ocurrido un error");
    //       });
    //   })
    //   .catch(err => {
    //     console.log(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     //document.location.replace("https://museo.savilecode.com/login");
    //   });

    if (refresh) {
      const config2 = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + refresh,
        },
      };

      axios
        .get(
          "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections",
          config2
        )
        .then(res => {
          CollectionService.refreshCurrentCollection(res.data);
          this.setState({ collection: res.data });
        })
        .catch(err => {
          alert("Ha ocurrido un error");
        });
    }
  }

  render() {
    const { labels, galleries, user, isLoading, error } = this.state;
    const { currentUser, collection } = this.state;
    //let collectionList;
    const t = this.props;

    console.log("Current User", currentUser);
    console.log("Collection", collection);
    let galleriesList;
    let collectionList;

    if (galleries != null && galleries.length > 0) {
      galleriesList = galleries.map(gallery => (
        <GalleryList
          className="fade-in-gallery"
          key={gallery.id}
          id={gallery.id}
          labels={gallery.labels}
          title={gallery.translations[i18n.language].title}
          artworks={gallery.artworks}
          isCollection={"museumGallery"}
        />
      ));
    }

    if (collection !== null) {
      collectionList = collection.galleries.map(({ id, title, artworks }) => {
        if (id !== null) {
          return (
            <div className="full-width">
              <GalleryList
                key={id}
                id={id}
                title={title}
                user_artworks={artworks}
                isCollection={"userCollection"}
              />
            </div>
          );
        }
      });
    }

    // Error Condition
    if (error) {
      return <p className="margin-top">{error.message}</p>;
    }

    return (
      <div>
        <div className="video-container">
          <video className="videoTag" playsInline autoPlay loop muted>
            <source src={videoMuseo} type="video/mp4" />
          </video>
          <div className="gradient-backgound"></div>
        </div>
        <div className="Home-component">
          <Navbar
            user={this.state.currentUser}
            handler={this.handler}
            positionCss="top"
          />
          <LogoNavbar />
          {isLoading ? (
            <p>Cargando...</p>
          ) : (
            <>
              <FilterList labels={labels} />
              {galleriesList}
              {collectionList}
              <Footer t={t} />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Home;
