import React, { Component } from "react";
import { Link } from "react-router-dom";
import LanguageSelector from "../translation/LanguageSelector";
import perfil from "../../images/perfil.svg";

// Import Images

import gac from "../../images/social/gac.svg";
import facebook from "../../images/social/facebook_museo.svg";
import twitter from "../../images/social/twitter_museo.svg";
import instagram from "../../images/social/instagram.svg";
import youtube from "../../images/social/youtube.svg";

import { i18n, t } from "../translation/i18n";
import { useTranslation } from "react-i18next";

import "./Footer.css";

function Footer() {
  const [t, i18n] = useTranslation();
  return (
    <div className="Footer">
      <div className="rights-box">
        <p style={{ fontSize: "0.8em" }}>
          © {new Date().getFullYear()} {t("Footer.Museo")}
        </p>
      </div>
      <div className="rrss-box">
        <a href="https://twitter.com/museobilbao" target="_blank">
          <img src={twitter} title="Twitter Logo" alt="Twitter Logo" />
        </a>
        <a href="https://www.facebook.com/museobilbao/" target="_blank">
          <img src={facebook} title="Facebook Logo" alt="Facebook Logo" />
        </a>
        <a href="https://www.youtube.com/user/MuseoBBAABilbao" target="_blank">
          <img src={youtube} title="Youtube Logo" alt="Youtube Logo" />
        </a>
        <a
          href=""
          href="https://www.instagram.com/museobilbao/"
          target="_blank"
        >
          <img src={instagram} title="Instagram Logo" alt="Instagram Logo" />
        </a>
        <a
          href="https://artsandculture.google.com/partner/museo-de-bellas-artes-de-bilbao"
          target="_blank"
        >
          <img
            src={gac}
            title="Google Arts and Culture Logo"
            alt="Google Arts and Culture Logo"
          />
        </a>
      </div>
      <div className="links-box">
        <a
          href="https://museobilbao.com/accesibilidad-web/"
          target="_blank"
          rel="noreferrer"
        >
          <p>{t("Footer.Access")}</p>
        </a>
        <a
          href="https://museobilbao.com/mapa-web/"
          target="_blank"
          rel="noreferrer"
        >
          <p>{t("Footer.Web Map")}</p>
        </a>
        <a
          href="https://museobilbao.com/aviso-legal/"
          target="_blank"
          rel="noreferrer"
        >
          <p>{t("Footer.Legal")}</p>
        </a>
        <a
          href="https://museobilbao.com/politica-privacidad/"
          target="_blank"
          rel="noreferrer"
        >
          <p>{t("Footer.Politics")}</p>
        </a>
        <a
          href="https://museobilbao.com/politica-de-cookies/"
          target="_blank"
          rel="noreferrer"
        >
          <p>{t("Footer.Cookies")}</p>
        </a>
      </div>
    </div>
  );
}

export default Footer;
