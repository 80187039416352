import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

// Import Images

import map from "../../images/map/plano-museo-oscuro.png";
import location from "../../images/navigation/location.svg";
import close from "../../images/navigation/close_24px.svg";
import locationOff from "../../images/map/location_off_black_24dp.svg";
import schedule from "../../images/information/schedule_24px.svg";
import description from "../../images/information/info_white_24px.svg";

import CollectionService from "../services/collection.service";
import AuthService from "../services/auth.service";
import Navbar from "../functionality/Navbar";

import "./MuseumMap.css";
import {
  getArtworkWithUrlFriendlyFormat,
  getURLSearchParamsValues,
} from "../../utils";

class MuseumMap extends Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);

    this.state = {
      gallery: [],
      isLogged: false,
      isLoding: false,
      error: null,
      token: "",
      refresh: null,
      // user Collection state
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
    };
  }

  get artworkId() {
    const [artworkId] = this.props.match.params.artwork.split("-");
    return artworkId;
  }

  componentDidMount() {
    this.setState({ isLoading: true }); // Loading... Spinner condition
    this.setState({ currentUser: AuthService.getCurrentUser() });
    this.setState({ collection: CollectionService.getCurrentCollection() });
    this.setState({ refresh: this.update_token() });

    window.scrollTo(0, 0);

    const [galleryId, isCollection] = getURLSearchParamsValues(
      this.props.location.search,
      ["galleryId", "isCollection"]
    );

    if (isCollection == "userLikes") {
      if (this.artworkId != undefined) {
        let APIG = `https://backend.itinerariosonline.museobilbao.com:8081/api/artworks/${this.artworkId}/`;

        // API Request Galleries
        axios
          .get(APIG)
          .then(response => {
            this.setState({ artwork: response.data, isLoading: false });
          })
          .catch(error => this.setState({ error, isLoading: false }));
      }
    } else if (isCollection == "userLikes") {
      this.setState({ isLoading: false });
    }

    if (isCollection == "museumGallery") {
      if (galleryId != undefined) {
        // let APIG = `https://backend.itinerariosonline.bilbaomuseoa.eus/galleries/${galleryId}/`;
        let APIG = `https://backend.itinerariosonline.museobilbao.com:8081/api/collections/`;

        // API Request Galleries
        axios
          .get(APIG)
          .then(response => {
            const gallery = response.data.galleries.filter(
              ({ id }) => id === +galleryId
            );
            this.setState({ gallery: gallery[0], isLoading: false });
          })
          .catch(error => this.setState({ error, isLoading: false }));
      }
    } else if (isCollection == "userCollection") {
      if (this.state.collection != null) {
        this.setState({ isLoading: false });
      }
    }
  }

  handler = value => {
    this.setState({
      language: value,
    });
  };

  async update_token() {
    const body = {
      refresh: localStorage.getItem("refresh"),
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    await axios
      .post(
        "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
        body,
        config
      )
      .then(res => {
        this.setState({ token: res.data.access });
        const config2 = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + res.data.access,
          },
        };

        axios
          .get(
            "https://backend.itinerariosonline.bilbaomuseoa.eus/collections/",
            config2
          )
          .then(res => {
            CollectionService.refreshCurrentCollection(res.data);
            this.setState({ collection: res.data });
          })
          .catch(err => {
            alert("Ha ocurrido un error");
          });
      })
      .catch(err => {
        console.log(
          "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
        );
        //document.location.replace("https://museo.savilecode.com/login");
      });
  }

  getArtworkList() {
    const { gallery, collection } = this.state;
    const [galleryId, isCollection] = getURLSearchParamsValues(
      this.props.location.search,
      ["galleryId", "isCollection"]
    );
    if (isCollection === "userLikes") {
      return false;
    }

    let artworkList = [];
    if (gallery.artworks !== undefined) artworkList = gallery.artworks || false;
    if (isCollection === "userCollection" && collection != null) {
      artworkList =
        collection.galleries
          .filter(({ id }) => id === +galleryId)
          .map(({ artworks }) => artworks)[0] || false;
    }
    return (
      artworkList &&
      artworkList.length &&
      artworkList.sort((a, b) => !!b.position_x - !!a.position_x)
    );
  }

  render() {
    const [galleryId, isCollection] = getURLSearchParamsValues(
      this.props.location.search,
      ["galleryId", "isCollection"]
    );
    const { isLoading, error, gallery, collection } = this.state;
    const { t, i18n } = this.props;
    let artworksList;
    let museumPoints;
    let time = 0;
    let museumMapTitle;

    // Error Condition
    if (error) {
      return <p className="margin-top">{error.message}</p>;
    }

    // Loading Spinner Condition
    if (isLoading) {
      return <p>Loading...</p>;
    }

    const artworkList = this.getArtworkList();
    let counterPoints = 0;
    museumPoints =
      artworkList &&
      artworkList.length &&
      artworkList.map(artwork => {
        if (!artwork.position_x)
          return (
            <React.Fragment key={`${artwork.id}-point-list`}></React.Fragment>
          );
        var xPercentage = (artwork.position_x / 690) * 100 + 10;
        var yPercentage = (artwork.position_y / 839) * 100;
        counterPoints++;
        return (
          <div
            key={`${artwork.id}-point-list`}
            style={{
              backgroundColor: "#b49449",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "40px",
              width: "40px",
              borderRadius: "100%",
              position: "absolute",
              zIndex: "100",
              top: yPercentage + "%",
              left: xPercentage + "%",
            }}
          >
            {counterPoints}
          </div>
        );
      });

    let counterList = 0;
    artworksList =
      artworkList &&
      artworkList.length &&
      artworkList.map(artwork => {
        time += artwork.artwork_time;
        counterList += artwork.position_x ? 1 : 0;
        const title = !!artwork.translations
          ? artwork.translations[i18n.language].title
          : artwork.title;

        const artworkUrl = getArtworkWithUrlFriendlyFormat(artwork.id, title);
        return (
          <Link
            key={`${artwork.id}-element-list`}
            className={artwork.position_x ? "" : "disabled-link"}
            to={{
              pathname: `/obras/${artworkUrl}`,
              search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
            }}
          >
            <div className="Itinerary-list">
              <div className="Itinerary-image">
                <img alt={artwork.title} src={artwork.thumbnail} />
              </div>
              <div className="Itinerary-index">
                <h5>
                  {artwork.position_x ? (
                    counterList
                  ) : (
                    <img src={locationOff} alt="LocationOff" id="LocationOff" />
                  )}
                </h5>
              </div>
              <div className="Itinerary-info">
                <h1>{title}</h1>
                {artwork.position_x !== undefined ? (
                  <p>{artwork.location}</p>
                ) : (
                  <p>{t("museumMap.No location")}</p>
                )}
              </div>
            </div>
          </Link>
        );
      });

    if (isCollection == "isCollection") {
      museumMapTitle = <h2>{collection.title}</h2>;
    } else if (isCollection == "userLikes") {
      museumMapTitle = <h2>{t("museumMap.TitleItinerary")}</h2>;
    } else if (isCollection == "museumGallery") {
      museumMapTitle = (
        <h2>
          {(gallery.translations &&
            gallery?.translations[i18n.language]?.title) ||
            ""}
        </h2>
      );
    } else {
      museumMapTitle = <h2>{t("museumMap.TitleItinerary")}</h2>;
    }

    return (
      <div className="MuseumMap">
        <Navbar user={this.state.currentUser} handler={this.handler} />
        <div className="ArtworkInfo-closebar">
          <Link
            to={{
              pathname: `/obras/info/${this.props.match.params.artwork}`,
              search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
            }}
          >
            <img
              src={close}
              title="Cerrar"
              alt="Cerrar Pantalla Información"
              id="cerrar"
            />
          </Link>
        </div>
        <div className="MuseumMap-box">
          <div className="MuseumMap-info">
            {museumMapTitle}
            <div className="div-flex">
              <img src={schedule} title="Horario Logo" alt="Horario Logo" />
              <h6>
                {t("museumMap.Estimated")}
                {time} {t("museumMap.Minutes")}
              </h6>
            </div>
            <p>{t("museumMap.More minutes")}</p>
            <br />
            {/* <a className="download-itinerary" href="#">
              <img
                src={description}
                title="Download Itinerary Plan PDF"
                alt="Download Itinerary Plab PDF"
              />
              <p>{t("museumMap.Download")}</p>
            </a> */}
            <h4>{t("museumMap.Itinerary")}</h4>
            <div className="MuseumMap-itinerary">{artworksList}</div>
          </div>
          <div className="MuseumMap-container">
            <div className="museumMap-image">
              <img src={map} />
              {museumPoints}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MuseumMap;
