import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import Footer from "../functionality/Footer";
import Navbar from "../functionality/Navbar";

import { Link } from "react-router-dom";

import "./Legal.css";

function TermsAndConditions(props) {
  const { t, i18n } = props;

  return (
    <div className="TermsAndConditions">
      <Navbar />
      <LogoNavbar />
      <div className="legal-box">
        <h1>Política de Privacidad</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
          sagittis feugiat gravida. Praesent ultricies ipsum vitae placerat
          bibendum. Etiam at aliquet metus. Cras commodo eu nisl vel fringilla.
          Donec quis euismod diam. Sed neque ex, dictum eu neque vitae,
          malesuada bibendum massa. Integer rhoncus placerat felis, ac congue
          tellus sagittis vel. Cras interdum dignissim diam, quis porta felis
          cursus vel. Fusce congue erat nec consectetur rhoncus. Duis vitae
          felis est. Quisque tincidunt lobortis vehicula. Ut quis nibh dapibus,
          mollis est id, cursus justo. Nam sed arcu iaculis, mattis odio ac,
          ullamcorper magna. <br /> <br />
          Donec eu neque nec urna pellentesque dictum sed quis neque. Nunc at
          diam nibh. Vivamus turpis nunc, mollis et felis varius, tristique
          dictum justo. Curabitur neque ligula, convallis dignissim velit in,
          bibendum maximus arcu. Ut sit amet consequat leo. Duis sed mollis
          orci, ac suscipit nibh. Sed vitae neque sed lectus bibendum vehicula
          in in velit. Ut lacus sem, auctor nec eros vitae, viverra consequat
          quam. Nullam vitae metus hendrerit, feugiat eros eget, porttitor leo.
          Quisque commodo dapibus purus, a elementum ligula vulputate ut.
          Curabitur commodo sem at augue iaculis sagittis. Nullam tempor ut
          risus non iaculis. Vestibulum nec odio pellentesque, mollis libero id,
          semper felis. <br /> <br />
          Phasellus vel interdum ante. Vestibulum posuere malesuada porta. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Sed orci leo,
          dapibus nec turpis eget, accumsan efficitur quam. Mauris scelerisque
          erat enim, in volutpat massa dapibus eu. Quisque a lectus in lacus
          aliquam viverra. Morbi fringilla egestas felis, id egestas magna
          ultricies a. Phasellus faucibus sapien non maximus finibus. Maecenas
          eu finibus justo. Ut ullamcorper lorem at luctus rhoncus. Proin et
          tempus urna.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
