import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import i18n from "../translation/i18n";
import "./FilterList.css";

const FilterList = ({ labels }) => {
  const [filters, setFilters] = useState([]);
  const [all, setAll] = useState(false);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!search) return;
    const filters = query.get("filterBy")
      ? query
          .get("filterBy")
          .split(",")
          .map(e => +e)
      : [];
    setFilters(filters);
  }, []);

  const toggleFilter = id => {
    return filters.includes(id)
      ? filters.filter(el => el !== id)
      : [...filters, id];
  };

  const getSearchParams = id => {
    const newFilters = toggleFilter(id);
    if (!newFilters.length) return null;
    return `?filterBy=${newFilters}`;
  };

  const handleClick = id => {
    if (!id) return setFilters([]);
    const newFilters = toggleFilter(id);
    setFilters(newFilters);
  };

  const isLinkActive = id => filters.includes(id);

  const showLink = main_label => !!(all || main_label);

  const labels_min = labels.map(({ id, translations, main_label }) => {
    return (
      showLink(main_label) && (
        <NavLink
          key={id}
          id={id}
          activeClassName="mystyle"
          isActive={() => isLinkActive(id)}
          onClick={() => handleClick(id)}
          to={{
            pathname: "/",
            search: getSearchParams(id),
          }}
          className="filter-default"
        >
          {translations[i18n.language].title}
        </NavLink>
      )
    );
  });

  //renderiza los botones del filtro
  const [all_button, moreLabel, lessLabel] = (() => {
    if (i18n.language === "en") return ["All", "More", "Less"];
    if (i18n.language === "es") return ["Todos", "Más", "Menos"];
    return ["Denok", "Gehiago", "Gutxiago"];
  })();

  return (
    <div className="FilterList">
      <NavLink
        to={{ pathname: "/" }}
        onClick={() => handleClick()}
        activeClassName="mystyle"
        isActive={() => !filters.length}
        id="all"
        className="filter-default"
      >
        {all_button}
      </NavLink>
      {labels_min}
      {all ? (
        <a id="less" onClick={() => setAll(false)}>
          {lessLabel}
        </a>
      ) : (
        <a id="more" onClick={() => setAll(true)}>
          {moreLabel}
        </a>
      )}
    </div>
  );
};

export default FilterList;
