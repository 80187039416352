import React, { useState } from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
import "./UserLikes.css";
import edit from "../../images/action/edit_image.svg";
import back from "../../images/navigation/arrow_back_grey.svg";
import eye from "../../images/content/visibility_24px.svg";
import line from "../../images/content/Line.svg";
import { Link } from "react-router-dom";
import GalleryList from "../gallery/GalleryList";

import i18n from "../translation/i18n";

class UserLikes extends React.Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      token: "",
      refresh: "",
      pk: "",
      error: null,
      resp: null,
      likes: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
    this.setState({ refresh: this.update_token() });
  }

  componentDidUpdate() {
    var art = document.getElementsByClassName("artwork-list");
    for (var i = 0; i < art.length; i++) {
      art[i].classList.remove("Unselected_style");
    }
  }

  async update_token() {
    const refresh = localStorage.getItem("refresh");
    // const body = {
    //   refresh: localStorage.getItem("refresh"),
    // };
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //   })
    //   .catch(err => {
    //     swal(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     document.location.replace(
    //       "https://itinerariosonline.museobilbao.com/login"
    //     );
    //   });

    if (refresh) {
      const config2 = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + refresh,
        },
      };

      axios
        .get(
          "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/likes",
          config2
        )
        .then(res => {
          // CollectionService.refreshCurrentCollection(res.data);
          this.setState({ likes: res.data.likes });

          console.log(res);
        })
        .catch(err => {
          alert("Ha ocurrido un error");
        });
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { currentUser } = this.state;
    console.log("USER like" + currentUser.like);

    return (
      <div className="Home-component">
        <LogoNavbar />
        <div className="back">
          <Link to="/">
            <img src={back} title="Atrás" alt="Atrás" id="atras" />
          </Link>
          <label>{t("user.home")}</label>
        </div>
        <div className="line-div">
          <div className="menu-line">
            <label id="collections">{t("user.like")}</label>
            <Link to="/user-collections">
              <label>{t("user.collections")}</label>
            </Link>
            <Link to="/user-information">
              <label>{t("user.profile")}</label>
            </Link>
          </div>
          <img
            src={line}
            alt="Linea"
            title="Linea"
            style={{ width: "110%", marginLeft: "-8%" }}
          />
        </div>
        {this.state.likes && (
          <GalleryList
            key={currentUser.id}
            title="Obras Que Me Gustan"
            user_artworks={this.state.likes}
            isCollection={"userLikes"}
          />
        )}
        <div>{this.state.error}</div>
      </div>
    );
  }
}

export default UserLikes;
