import React, { Component } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLoginAPI from "./FacebookLogin";

import facebook from "../../images/social/facebook.svg";

class FacebookSocialAuth extends Component {
  render() {
    const fbResponse = response => {
      FacebookLoginAPI(response.accessToken);
    };
    return (
      <div className="Facebook-login">
        <FacebookLogin
          appId="1188313198344696"
          fields="name,email,picture"
          callback={fbResponse}
          render={renderProps => (
            <button className="Facebook-button" onClick={renderProps.onClick}>
              <img src={facebook} title="Facebook Logo" alt="Facebook Logo" />
              Entrar con facebook
            </button>
          )}
        />
      </div>
    );
  }
}

export default FacebookSocialAuth;
