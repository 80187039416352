import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
import "./UserCollections.css";
import edit from "../../images/action/edit_image.svg";
import back from "../../images/navigation/arrow_back_grey.svg";
import eye from "../../images/content/visibility_24px.svg";
import line from "../../images/content/Line.svg";
import { Link } from "react-router-dom";
import CollectionList from "../gallery/CollectionList";
import CollectionService from "../services/collection.service";
import i18n from "../translation/i18n";

class UserCollections extends React.Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
      token: "",
      refresh: "",
      pk: "",
      error: null,
      resp: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
    this.setState({ refresh: this.update_token() });
  }

  componentDidUpdate() {
    var art = document.getElementsByClassName("artwork-list");
    for (var i = 0; i < art.length; i++) {
      art[i].classList.remove("Unselected_style");
    }
  }

  async update_token() {
    const body = {
      refresh: localStorage.getItem("refresh"),
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //     const config2 = {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + res.data.access,
    //       },
    //     };

    //     axios
    //       .get(
    //         "https://backend.itinerariosonline.bilbaomuseoa.eus/collections/",
    //         config2
    //       )
    //       .then(res => {
    //         CollectionService.refreshCurrentCollection(res.data);
    //         this.setState({ collection: res.data });
    //       })
    //       .catch(err => {
    //         swal("Ha ocurrido un error");
    //       });
    //   })
    //   .catch(err => {
    //     swal(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     document.location.replace(
    //       "https://itinerariosonline.museobilbao.com/login"
    //     );
    //   });

    await axios
      .get(
        "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections",
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("refresh"),
          },
        }
      )
      .then(res => {
        CollectionService.refreshCurrentCollection(res.data);
        this.setState({ collection: res.data });
      })
      .catch(err => {
        swal("Ha ocurrido un error");
      });
  }

  render() {
    const { t, i18n } = this.props;
    const { currentUser, collection } = this.state;
    let index = 0;

    const collectionList = collection?.galleries?.map(
      ({ id, title, artworks, description }) => {
        index = index + 1;
        return (
          <CollectionList
            key={id}
            id={id}
            title={title}
            artworks={artworks}
            description={description}
          />
        );
      }
    ) || <></>;
    if (collectionList) {
      return (
        <div className="Home-component">
          <LogoNavbar />
          <div className="back">
            <Link to="/">
              <img src={back} title="Atrás" alt="Atrás" id="atras" />
              <label className="back-label">{t("user.home")}</label>
            </Link>
          </div>
          <div className="line-div">
            <div className="menu-line">
              <Link to="/user-likes">
                <label>{t("user.like")}</label>
              </Link>
              <label id="collections">{t("user.collections")}</label>
              <Link to="/user-information">
                <label>{t("user.profile")}</label>
              </Link>
            </div>
            <img
              src={line}
              alt="Linea"
              title="Linea"
              style={{ width: "110%", marginLeft: "-8%" }}
            />
          </div>
          <div className="collections-box">
            <div className="collections">{collectionList}</div>
            <div>{this.state.error}</div>
          </div>
        </div>
      );
    } else {
      if (i18n.language == "en") {
        swal("You have no collections created yet.");
      } else if (i18n.language == "es") {
        swal("Aún no tiene colecciones creadas.");
      } else {
        swal("Oraindik ez duzu bildumarik sortu.");
      }
    }
  }
}

export default UserCollections;
