import React, { Component } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import googleLogin from "./googleLogin";

import google from "../../images/social/google.svg";

const CLIENT_ID =
  "170068634815-veiukfaij7gfcsvu389o4fnodhvo7ovg.apps.googleusercontent.com";

class GoogleSocialAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
      accessToken: "",
      tokenId: "",
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  login(response) {
    if (response.accessToken) {
      let googleResponse = googleLogin(response.accessToken);
      this.setState(state => ({
        isLogined: true,
        accessToken: response.accessToken,
        tokenId: response.tokenId,
      }));
    }
  }

  logout(response) {
    this.setState(state => ({
      isLogined: false,
      accessToken: "",
      tokenId: "",
    }));
  }

  handleLoginFailure(response) {
    if (response) {
      console.log("Failed to log in");
    }
  }

  handleLogoutFailure(response) {
    if (response) {
      console.log("Failed to log out");
    }
  }

  render() {
    return (
      <div className="Google-login">
        {this.state.isLogined ? (
          <GoogleLogout
            clientId={CLIENT_ID}
            buttonText="Logout"
            render={renderProps => (
              <button
                className="Google-button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img src={google} title="Google Logo" alt="Google Logo" />
                Entrar con Google
              </button>
            )}
            onLogoutSuccess={this.logout}
            onFailure={this.handleLogoutFailure}
          ></GoogleLogout>
        ) : (
          <GoogleLogin
            clientId={CLIENT_ID}
            buttonText="Entrar con Google"
            render={renderProps => (
              <button
                className="Google-button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img src={google} title="Google Logo" alt="Google Logo" />
                Entrar con Google
              </button>
            )}
            onSuccess={this.login}
            onFailure={this.handleLoginFailure}
            cookiePolicy={"single_host_origin"}
            responseType="code,token"
          />
        )}
      </div>
    );
  }
}

export default GoogleSocialAuth;
