import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
import "./CreateCollection.css";
import back from "../../images/navigation/arrow_back_grey.svg";
import line from "../../images/content/Line.svg";
import edit from "../../images/action/edit_image.svg";
import { Link } from "react-router-dom";
import GalleryList from "../gallery/GalleryList";
import CollectionService from "../services/collection.service";

import i18n from "../translation/i18n";

class CreateCollection extends React.Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeData = this.changeData.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
      title: "",
      description: "",
      token: "",
      refresh: "",
      pk: "",
      error: null,
      resp: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
    this.setState({ refresh: this.update_token() });
    // const refresh = localStorage.getItem("refresh");
    // this.setState({ token: refresh });
  }

  async update_token() {
    const refresh = localStorage.getItem("refresh");
    if (refresh) return this.setState({ token: refresh });
    swal(
      "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    );
    document.location.replace(
      "https://itinerariosonline.museobilbao.com/login"
    );
    // const body = {
    //   refresh: localStorage.getItem("refresh"),
    // };
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //   })
    //   .catch(err => {
    //     swal(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     //document.location.replace("https://itinerariosonline.museobilbao.com/login");
    //   });
  }

  changeName(e) {
    this.setState({
      title: e.target.value,
    });
  }

  changeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  async changeData(e) {
    e.preventDefault();
    await this.update_token();
    let form_data = new FormData();
    form_data.append("title", this.state.title);
    form_data.append("owner", this.state.currentUser.id);
    form_data.append("description", this.state.description);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + this.state.token,
        },
      };

      // let url = `https://backend.itinerariosonline.bilbaomuseoa.eus/create_collection/`;
      let url = `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections`;

      await axios
        .post(
          url,
          { name: this.state.title },
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          }
        )
        .then(res => {
          const collection_id = res.data.id;
          const art = [];
          art[0] = this.props.match.params.artworkId;
          const body = {
            title: this.state.title,
            artworks: art,
          };
          const config2 = {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          };
          axios
            .post(
              `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections/${collection_id}/artworks`,
              // `https://backend.itinerariosonline.bilbaomuseoa.eus/add_artwork_to_collection/${collection_id}/`,
              { artwork_id: this.props.match.params.artworkId },
              config2
            )
            .then(res => {
              // this.setState({ resp: res.data });
              swal("Se ha creado la colección y se ha agregado la obra.");
              this.props.history.push("/user-collections");
            })
            .catch(err => {
              swal(
                "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
              );
            });
          axios
            .get(
              "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections",
              config2
            )
            .then(res => {
              CollectionService.refreshCurrentCollection(res.data);
              this.setState({ collection: res.data });
            })
            .catch(err => {
              if (i18n.language == "en") {
                swal("An error has occurred");
              } else if (i18n.language == "es") {
                swal("Ha ocurrido un error");
              } else {
                swal("Akats bat gertatu da");
              }
            });
        })
        .catch(err => console.log(err));
      if (i18n.language == "en") {
        swal("Successfully created collection");
      } else if (i18n.language == "es") {
        swal("Colección creada satisfactoriamente");
      } else {
        swal("Sortu da bilduma");
      }
    } catch {
      if (i18n.language == "en") {
        swal(
          "The new collection has not been created, try logging in again and repeat the process."
        );
      } else if (i18n.language == "es") {
        swal(
          "No se ha creado la nueva colección intente logearse nuevamente y repetir el proceso."
        );
      } else {
        swal(
          "Bilduma berria ez da sortu, saiatu berriro saioa hasten eta errepikatu prozesua."
        );
      }
    }
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div className="Home-component">
        <LogoNavbar />
        <div className="back">
          <Link to="/">
            <img src={back} title="Atrás" alt="Atrás" id="atras" />
          </Link>
          <label>{t("user.home")}</label>
        </div>
        <div className="line-div">
          <div className="menu-line">
            <Link to="/user-likes">
              <label>{t("user.like")}</label>
            </Link>
            <Link to="/user-collections">
              <label>{t("user.collections")}</label>
            </Link>
            <Link to="/user-information">
              <label>{t("user.profile")}</label>
            </Link>
          </div>
          <img
            src={line}
            alt="Linea"
            title="Linea"
            style={{ width: "110%", marginLeft: "-8%" }}
          />
        </div>
        <div className="create-collection-div">
          <h4>{t("user.create new")}:</h4>
          <form className="collection-form" onSubmit={this.changeData}>
            <div className="collection-name">
              <div className="collection-tuple">
                <label>{t("user.name collection")}</label>
                <input
                  value={this.state.title}
                  type="input"
                  name="title"
                  onChange={this.changeName}
                />
              </div>
            </div>
            <div className="collection-description">
              <label>{t("user.description")}</label>
              <textarea
                value={this.state.description}
                name="description"
                id="description"
                onChange={this.changeDescription}
              />
            </div>
            <button className="submit-collection" type="submit" value="Submit">
              {t("user.create")}
            </button>
          </form>
        </div>

        <div>{this.state.error}</div>
      </div>
    );
  }
}

export default CreateCollection;
