import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
import "../functionality/UserCollections.css";
import "./Collection.css";
import edit from "../../images/action/edit_image.svg";
import back from "../../images/navigation/arrow_back_grey.svg";
import line from "../../images/content/Line.svg";
import { Link } from "react-router-dom";
import GalleryList from "../gallery/GalleryList";
import CollectionService from "../services/collection.service";
import i18n from "../translation/i18n";

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.changeData = this.changeData.bind(this);
    this.deleteArtworks = this.deleteArtworks.bind(this);
    this.deleteCollection = this.deleteCollection.bind(this);
    this.fillArts = this.fillArts.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
      title: "",
      description: "",
      token: "",
      refresh: "",
      pk: "",
      error: null,
      resp: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
    this.setState({ collection: CollectionService.getCurrentCollection() });
    this.setState({ refresh: this.update_token() });
    this.fillArts();
  }

  fillArts(e) {
    const collections = this.state.collection.galleries;
    console.log(collections);
    var i;
    for (i = 0; i < collections.length; i++) {
      if (this.props.match.params.collectionId == collections[i].id) {
        this.setState({ title: collections[i].title });
        this.setState({ description: collections[i].description });
        var options = document.querySelectorAll("#select-art option");
        options.forEach(o => o.remove());

        var j;
        for (j = 0; j < collections[i].artworks.length; j++) {
          var z = document.createElement("option");
          z.setAttribute("value", collections[i].artworks[j].id);
          var t = document.createTextNode(
            collections[i].artworks[j].translations[i18n.language].title
          );
          z.appendChild(t);
          document.getElementById("select-art").appendChild(z);
        }
      }
    }
  }

  async update_token() {
    const refresh = localStorage.getItem("refresh");
    if (refresh) return this.setState({ token: refresh });
    swal(
      "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    );
    document.location.replace(
      "https://itinerariosonline.museobilbao.com/login"
    );

    // const body = {
    //   refresh: localStorage.getItem("refresh"),
    // };
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //     const config2 = {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + res.data.access,
    //       },
    //     };
    //   })
    //   .catch(err => {
    //     swal(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     document.location.replace(
    //       "https://itinerariosonline.museobilbao.com/login"
    //     );
    //   });
  }

  changeName(e) {
    this.setState({
      title: e.target.value,
    });
  }

  changeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  async changeData(e) {
    e.preventDefault();
    await this.update_token();

    // const body = {
    //   title: this.state.title,
    //   description: this.state.description,
    // };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    };

    const id = this.props.match.params.collectionId;
    axios
      .put(
        `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections/${id}/`,
        { name: this.state.title },
        config
      )
      .then(res => {
        this.setState({ resp: res.data });
        axios
          .get(
            "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections",
            config
          )
          .then(res_get => {
            CollectionService.refreshCurrentCollection(res_get.data);
            if (i18n.language == "en") {
              swal("The collection has been updated");
            } else if (i18n.language == "es") {
              swal("Se ha actualizado la colección");
            } else {
              swal("Bilduma eguneratu da");
            }
          });
      })
      .catch(err => {
        swal(
          "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
        );
        //document.location.replace("https://itinerariosonline.museobilbao.com/login");
      });
  }

  async deleteArtworks(e) {
    e.preventDefault();
    //await this.update_token();
    let selectElement = document.getElementById("select-art");
    let artworks = Array.from(selectElement.selectedOptions).map(
      option => option.value
    );

    if (!artworks.length) return false;

    const { collectionId } = this.props.match.params;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    };

    axios
      .delete(
        `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections/${collectionId}/artworks/${artworks.join(
          "-"
        )}`,
        config
      )
      .then(res_get => {
        axios
          .get(
            "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections",
            config
          )
          .then(res_get => {
            CollectionService.refreshCurrentCollection(res_get.data);
            const newArtworksLength = res_get.data.galleries.find(
              gal => gal.id === +collectionId
            ).artworks.length;

            if (!newArtworksLength)
              return this.props.history.push("/user-collections");

            this.setState({ collection: res_get.data });
            this.fillArts();
            if (i18n.language == "en") {
              swal("The collection has been updated");
            } else if (i18n.language == "es") {
              swal("Se ha actualizado la colección");
            } else {
              swal("Bilduma eguneratu da");
            }
          });
      })
      .catch(err => {
        swal(
          "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
        );
        //document.location.replace("https://itinerariosonline.museobilbao.com/login");
      });
  }

  async deleteCollection(e) {
    e.preventDefault();
    await this.update_token();
    const config = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    };

    const id = this.props.match.params.collectionId;

    axios
      .delete(
        `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/my-collections/${id}/`,
        config
      )
      .then(res => {
        // this.setState({ resp: res.data });
        this.props.history.push("/user-collections");
      })
      .catch(err => {
        console.log(err);
      });
    if (i18n.language == "en") {
      swal("You have successfully deleted the collection");
    } else if (i18n.language == "es") {
      swal("Ha eliminado la colección correctamente");
    } else {
      swal("Behar bezala ezabatu duzu bilduma");
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { currentUser, collection } = this.state;

    const collectionList = collection.galleries.map(
      ({ id, title, artworks }) => {
        if (id == this.props.match.params.collectionId) {
          return <GalleryList key={id} title={title} artworks={artworks} />;
        }
      }
    );

    return (
      <div className="Home-component">
        <LogoNavbar />
        <div className="back">
          <Link to="/">
            <img src={back} title="Atrás" alt="Atrás" id="atras" />
          </Link>
          <label>{t("user.home")}</label>
        </div>
        <div className="line-div">
          <div className="menu-line">
            <Link to="/user-likes">
              <label>{t("user.like")}</label>
            </Link>
            <Link to="/user-collections">
              <label>{t("user.collections")}</label>
            </Link>
            <Link to="/user-information">
              <label>{t("user.profile")}</label>
            </Link>
          </div>
          <img
            src={line}
            alt="Linea"
            title="Linea"
            style={{ width: "110%", marginLeft: "-8%" }}
          />
        </div>
        <div className="create-collection-div">
          <h4>Editar Colección:</h4>
          <form className="form-select" onSubmit={this.deleteArtworks}>
            <select id="select-art" multiple></select>
            <button className="submit-collection" type="submit" value="Submit">
              {t("user.delete artworks")}
            </button>
          </form>
          <form className="collection-form" onSubmit={this.changeData}>
            <div className="collection-name">
              <div className="collection-tuple">
                <label>{t("user.name collection")}</label>
                <input
                  value={this.state.title}
                  type="input"
                  name="title"
                  onChange={this.changeName}
                />
              </div>
            </div>
            <div className="collection-description">
              <label>{t("user.description")}</label>
              <textarea
                value={this.state.description}
                name="description"
                id="description"
                onChange={this.changeDescription}
              />
            </div>
            <div className="buttons-div">
              <button
                className="submit-collection-transparent"
                type="button"
                onClick={this.deleteCollection}
              >
                {t("user.delete")}
              </button>
              <Link to="/user-collections">
                <button className="submit-collection-transparent" type="button">
                  {t("user.cancel")}
                </button>
              </Link>
              <button
                className="submit-collection"
                type="submit"
                value="Submit"
              >
                {t("user.save")}
              </button>
            </div>
          </form>
        </div>
        <div>{this.state.error}</div>
      </div>
    );
  }
}

export default Collection;
