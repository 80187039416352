import React from "react";
import { Link } from "react-router-dom";
import edit from "../../images/action/edit_collection.svg";

import "./CollectionList.css";

import { useTranslation } from "react-i18next";
import ImageGrid from "./ImageGrid";

function CollectionList({ title, description, artworks, id }) {
  const [t, i18n] = useTranslation();

  const qtty = artworks.length;
  const images = artworks.map(({ thumbnail }) => thumbnail);

  return (
    <div className="CollectionList">
      <Link
        to={{
          pathname: `/collectionDetail/${id}`,
        }}
      >
        <ImageGrid images={images} />
        <p className="qtty white-info">
          {qtty} {t("user.artworks")}
        </p>
        <h2 className="white-info">{title}</h2>
        <p className="white-info">{description}</p>
      </Link>
    </div>
  );
}

export default CollectionList;
