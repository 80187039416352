import React, { Component } from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
import "../functionality/UserCollections.css";
import "./Collection.css";
import edit from "../../images/action/edit_image.svg";
import back from "../../images/navigation/arrow_back_grey.svg";
import line from "../../images/content/Line.svg";
import { Link } from "react-router-dom";
import GalleryList from "../gallery/GalleryList";
import CollectionService from "../services/collection.service";

import i18n from "../translation/i18n";

class CollectionView extends Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
      title: "",
      description: "",
      cover_image: null,
      image_collection: null,
      token: "",
      refresh: "",
      pk: "",
      error: null,
      resp: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
    this.setState({ collection: CollectionService.getCurrentCollection() });
    this.setState({ refresh: this.update_token() });
  }

  componentDidUpdate() {
    var art = document.getElementsByClassName("artwork-list");
    for (var i = 0; i < art.length; i++) {
      art[i].classList.remove("Unselected_style");
    }
  }

  async update_token() {
    const body = {
      refresh: localStorage.getItem("refresh"),
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //     const config2 = {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //         Authorization: "Bearer " + res.data.access,
    //       },
    //     };
    //   })
    //   .catch(err => {
    //     swal(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     document.location.replace(
    //       "https://itinerariosonline.museobilbao.com/login"
    //     );
    //   });
  }

  handleClick() {
    document.getElementById("image_collection").click();
  }

  render() {
    const { t, i18n } = this.props;
    const { currentUser, collection } = this.state;

    const collectionList = collection.galleries.map(
      ({ id, title, artworks }) => {
        if (id == this.props.match.params.collectionId) {
          return (
            <div className="full-width">
              <div className="edit">
                <Link
                  to={{
                    pathname: `/collectionEdit/${this.props.match.params.collectionId}`,
                  }}
                >
                  <img src={edit} />
                </Link>
              </div>
              <GalleryList
                key={this.props.match.params.collectionId}
                id={id}
                title={title}
                user_artworks={artworks}
                isCollection={"userCollection"}
              />
            </div>
          );
        }
      }
    );

    return (
      <div className="Home-component">
        <LogoNavbar />
        <div className="back">
          <Link to="/">
            <img src={back} title="Atrás" alt="Atrás" id="atras" />
          </Link>
          <label>{t("user.home")}</label>
        </div>
        <div className="line-div">
          <div className="menu-line">
            <Link to="/user-likes">
              <label>{t("user.like")}</label>
            </Link>
            <Link to="/user-collections">
              <label>{t("user.collections")}</label>
            </Link>
            <Link to="/user-information">
              <label>{t("user.profile")}</label>
            </Link>
          </div>
          <img
            src={line}
            alt="Linea"
            title="Linea"
            style={{ width: "110%", marginLeft: "-8%" }}
          />
        </div>
        <div className="collections">{collectionList}</div>
        <div>{this.state.error}</div>
      </div>
    );
  }
}

export default CollectionView;
