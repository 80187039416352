import React from "react";

import LogoNavbar from "../functionality/LogoNavbar";
import Footer from "../functionality/Footer";
import Navbar from "../functionality/Navbar";

import { Link } from "react-router-dom";

import "./Legal.css";

function PrivacyPolicy(props) {
  const { t, i18n } = props;

  return (
    <div className="PrivacyPolicy">
      <Navbar />
      <div className="legal-box">
        <h1>{t("Legal.Copyright")}</h1>
        <p>
          © Copyright de esta web: FUNDACION MUSEO DE BELLAS ARTES DE BILBAO.
          2018
        </p>
        <p>
          © Succesion Antonio Saura /{" "}
          <a href="www.antoniosaura.org">www.antoniosaura.org</a> / A+V Agencia
          de Creadores Visuales 2018
        </p>
        <p>© Fondation Oskar Kokoschka, 2018, VEGAP, Bilbao</p>
        <p>© Fundació Antoni Tápies, VEGAP, Bilbao, 2018</p>
        <p>© Sucesión Pablo Picasso, VEGAP, Madrid, 2018</p>
        <p>© The State of Francis Bacon, VEGAP, Bilbao, 2018</p>
        <p>© Zabalaga - Leku, VEGAP, Bilbao, 2018</p>
        <p>© {t("Legal.Autorized")}, VEGAP, Bilbao, 2018</p>
        <h2>{t("Legal.Conditions")}</h2>
        <ul>
          <li>
            <p>{t("Legal.Presents")}</p>
          </li>
          <li>
            <p>{t("Legal.Data")}</p>
          </li>
          <li>
            <p>{t("Legal.Images")}</p>
          </li>
          <li>
            <p>{t("Legal.Names")}</p>
          </li>
          <li>
            <p>{t("Legal.User")}</p>
          </li>
          <li>
            <p>{t("Legal.Links")}</p>
          </li>
          <li>
            <p>{t("Legal.Museum")}</p>
          </li>
          <li>
            <p>{t("Legal.Services")}</p>
          </li>
          <li>
            <p>{t("Legal.Parts")}</p>
          </li>
          <li>
            <p>{t("Legal.Translate")}</p>
          </li>
        </ul>
        <h2>{t("Legal.Info")}</h2>
        <p>{t("Legal.Fundation")}</p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
