import React, { Component } from "react";
import ReactModal from "react-modal";

import "./Modal.css";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div>
        <button onClick={this.handleOpenModal}>{this.props.button}</button>
        <ReactModal
          className="Modal"
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <p>{this.props.text}</p>
          <button onClick={this.handleCloseModal}>X</button>
        </ReactModal>
      </div>
    );
  }
}

export default Modal;
