import { Link, Redirect, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import axios from "axios";

// Import Components
import LogoNavbarLogin from "./LogoNavbarLogin";
import GoogleSocialAuth from "./GoogleSocialAuth";
import FacebookSocialAuth from "./FacebookSocialAuth";

import Navbar from "./Navbar";

// Import Styles
import "./Login.css";

// Import Images
import logoImg from "../../images/logo.svg";
import { getErrorList } from "../../utils";

function Login() {
  const registerText =
    "Regístrate gratis para disfrutar de la experiencia completa";
  const [respok, changeResponseok] = useState(null);
  const [email, changeEmail] = useState("");
  const [password, changePassword] = useState("");
  const [errorMessage, changeMessage] = useState("");

  const [t, i18n] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("refresh")) {
      history.push("/user-information");
    }
  }, []);

  async function onSubmit(e) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    e.preventDefault();

    try {
      const { data } = await axios.post(
        "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/users/login",
        {
          email,
          password,
          device_name: "Mi Phone",
          lang: i18n.language,
        },
        config
      );

      if (data.access_token) {
        localStorage.setItem("id_token", data.access_token);
        localStorage.setItem("refresh", data.refresh_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        changeResponseok(data);
      }
    } catch (error) {
      const errors = getErrorList(error);
      changeMessage(errors);
      console.log("error ->", error);
    }
  }

  return (
    <div className="Login">
      <Navbar />
      <Link to="/">
        <LogoNavbarLogin text={registerText} />
      </Link>
      <div className="form-box">
        <form onSubmit={onSubmit}>
          <p className="line-height">{t("login.Login with mail")}</p>
          <input
            className="sticky-form"
            onChange={e => changeEmail(e.target.value)}
            value={email}
            type={"input"}
            name={"email"}
            placeholder={t("login.Email")}
          />
          <input
            className="sticky-form"
            onChange={e => changePassword(e.target.value)}
            value={password}
            type={"password"}
            name={"password"}
            placeholder={t("login.Password")}
          />
          <button className="sticky-form" type={"submit"}>
            {t("login.Log in")}
          </button>
          {errorMessage && <p className="error">{errorMessage}</p>}
          <div className="check">
            <div className="check-input">
              <input className="form-checkbox" type="checkbox" />
              <p className="remind-me">{t("login.Remind me")}</p>
            </div>
            <p className="forgot-password">
              <Link to="/reset">{t("login.Forgot your password?")}</Link>
            </p>
          </div>
        </form>
        <div className="social-login-box">
          <p>{t("login.Login with")}</p>
          <div className="login-button-box">
            <GoogleSocialAuth
              id="google-button"
              className="social-login-button"
            />
            <FacebookSocialAuth
              id="facebook-button"
              className="social-login-button"
            />
          </div>
          <div className="dont-have-account">
            <h6>{t("login.Do not have an account?")}&nbsp;</h6>
            <Link to="/signup">
              <h6>&nbsp;{t("login.Sign up")}</h6>
            </Link>
          </div>
        </div>
        <div>{respok && <Redirect to="/" />}</div>
      </div>
    </div>
  );
}

export default Login;

/*
 
<p>{t('login.Login with')}</p>
          <div className="login-button-box">
            <GoogleSocialAuth className="social-login-button" />
            <FacebookSocialAuth className="social-login-button" />
          </div>
 
 */
