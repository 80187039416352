import { Link, Redirect } from "react-router-dom";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import swal from "sweetalert";
import Swal from "sweetalert2";

// Import Styles
import "./Reset.css";

// Import Components
import LogoNavbarLogin from "./LogoNavbarLogin";
import Navbar from "./Navbar";

// Import Images
import logoImg from "../../images/logo.svg";

function Reset() {
  const registerText =
    "Regístrate gratis para disfrutar de la experiencia completa";
  const [resp, changeResponse] = useState(null);
  const [respok, changeResponseok] = useState(null);
  const [email, changeEmail] = useState("");
  const [errorMessage, changeMessage] = useState("");

  const [t, i18n] = useTranslation();

  function onSubmit(e) {
    e.preventDefault();
    return fetch(
      "https://backend.itinerariosonline.bilbaomuseoa.eus/dj-rest-auth/password/reset/",
      {
        method: "POST",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email }),
      }
    )
      .then(resp => resp.json())
      .then(data => {
        if (data.email == "This field may not be blank.") {
          if (i18n.language === "en") {
            changeMessage("This field may not be blank.");
          } else if (i18n.language === "es") {
            changeMessage("Este campo no puede estar vacío.");
          } else {
            changeMessage("Baliteke eremu hau hutsik ez egotea");
          }
        } else if (data.email == "Enter a valid email address.") {
          if (i18n.language === "en") {
            changeMessage("Enter a valid email address.");
          } else if (i18n.language === "es") {
            changeMessage("Introduce un email válido.");
          } else {
            changeMessage("Idatzi balio duen helbide elektronikoa.");
          }
        }
        if (data.email) {
          changeResponse(data);
        } else {
          //alert("Se ha enviado un correo a la cuenta proporcionada.")
          changeMessage("Se ha enviado un correo a la cuenta proporcionada");
          changeResponseok(data);
        }
      })
      .catch(error => console.log("error ->", error));
  }

  function onRedirect() {
    Swal.fire({
      title: "Se ha enviado un correo a la cuenta proporcionada",
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: `De Acuerdo`,
      confirmButtonColor: "#b49449",
    }).then(result => {
      if (result.isConfirmed) {
        <Redirect
          to={{
            pathname: "/login",
          }}
        />;
      }
    });
  }

  return (
    <div className="Reset">
      <Navbar />
      <Link to="/">
        <LogoNavbarLogin text={registerText} />
      </Link>
      <div className="form-box">
        <form onSubmit={onSubmit}>
          <p className="line-height ">{t("reset.Insert email")}</p>
          <input
            className="sticky-form"
            onChange={e => changeEmail(e.target.value)}
            value={email}
            type={"input"}
            name={"email"}
            placeholder={t("reset.Email")}
          />
          <button type={"submit"}>{t("reset.Recover password")}</button>
        </form>
        <div>
          {respok && <Redirect to="/login" />}
          {errorMessage && <p className="error"> {errorMessage} </p>}
        </div>
      </div>
    </div>
  );
}

export default Reset;
