import "./ImageGrid.css";

const ImageGrid = ({ images = [], size = "", title = "" }) => {
  const imagesLength = images.length;
  return (
    <div className={size === "min" ? "cover-img-min" : "cover-img"}>
      <ul className="image-grid">
        {imagesLength === 1 ? (
          <img src={images[0]} alt={title} />
        ) : (
          images.map(img => (
            <li key={img}>
              <img src={img} alt={title} />
            </li>
          ))
        )}
        {imagesLength === 2 && (
          <>
            <li className="img--empty"></li>
            <li className="img--empty"></li>
          </>
        )}
        {imagesLength === 3 && <li className="img--empty"></li>}
        {/* {imagesLength > 4 && <li class="more"></li>} */}
      </ul>
    </div>
  );
};

export default ImageGrid;
