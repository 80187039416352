import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import Styles
import "./ResetConfirm.css";

// Import Components
import LogoNavbarLogin from "./LogoNavbarLogin";
import Navbar from "./Navbar";

const ResetConfirm = ({ match }) => {
  const registerText =
    "Regístrate gratis para disfrutar de la experiencia completa";
  const [resp, changeResponse] = useState(null);
  const [respok, changeResponseok] = useState(null);
  const [new_password1, changePassword1] = useState("");
  const [new_password2, changePassword2] = useState("");
  const [t, i18n] = useTranslation();

  function onSubmit(e) {
    e.preventDefault();
    const uid = match.params.uid;
    const token = match.params.token;

    return fetch(
      "https://backend.itinerariosonline.bilbaomuseoa.eus/dj-rest-auth/password/reset/confirm/",
      {
        method: "POST",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ new_password1, new_password2, uid, token }),
      }
    )
      .then(resp => resp.json())
      .then(data => {
        if (data.detail) {
          alert(
            "Se ha cambiado satisfactoriamente su contraseña ya puede acceder."
          );
          changeResponseok(data);
        } else {
          changeResponse(data);
        }
      })
      .catch(error => console.log("error ->", error));
  }

  return (
    <div className="ResetConfirm">
      <Navbar />
      <LogoNavbarLogin text={registerText} />
      <div className="form-box">
        <form onSubmit={onSubmit}>
          <p>{t("resetConfirm.Insert password")}</p>
          <input
            onChange={e => changePassword1(e.target.value)}
            value={new_password1}
            type={"password"}
            name={"new_password1"}
            placeholder={t("resetConfirm.New password")}
          />
          <input
            onChange={e => changePassword2(e.target.value)}
            value={new_password2}
            type={"password"}
            name={"new_password2"}
            placeholder={t("resetConfirm.Repeat password")}
          />
          <button type={"submit"}>{t("resetConfirm.Reset password")}</button>
        </form>
        <div>
          {respok && <Redirect to="/login" />}
          {resp && (
            <div className={"response"}>
              <code>{JSON.stringify(resp)}</code>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetConfirm;
