class CollectionService {
  deleteCollection() {
    localStorage.removeItem("collection");
  }

  getCurrentCollection() {
    return JSON.parse(localStorage.getItem("collection"));
  }

  refreshCurrentCollection(data) {
    localStorage.setItem("collection", JSON.stringify(data));
  }
}

export default new CollectionService();
