import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import Footer from "../functionality/Footer";
import Navbar from "../functionality/Navbar";

import { Link } from "react-router-dom";

import "./Legal.css";

function TermsAndConditions(props) {
  const { t, i18n } = props;

  return (
    <div className="TermsAndConditions">
      <Navbar />
      <div className="legal-box">
        <h1>{t("Accessibility.Web")}</h1>
        <p>{t("Accessibility.Site")}</p>
        <p>{t("Accessibility.Rules")}</p>
        <h2>{t("Accessibility.Standars")}</h2>
        <ul>
          <li>
            <p>{t("Accessibility.Needs")}</p>
          </li>
          <li>
            <p>{t("Accessibility.Pages")}</p>
          </li>
          <li>
            <p>{t("Accessibility.Css")}</p>
          </li>
        </ul>
        <h2>{t("Accessibility.Structure")}</h2>
        <p>{t("Accessibility.Allpages")}</p>
        <ul>
          <li>
            <p>{t("Accessibility.Head")}</p>
          </li>
          <li>
            <p>{t("Accessibility.Column")}</p>
          </li>
          <li>
            <p>{t("Accessibility.Area")}</p>
          </li>
          <li>
            <p>{t("Accessibility.Footer")}</p>
          </li>
        </ul>
        <p>{t("Accessibility.Documents")}</p>
        <ul>
          <li>
            <p>{t("Accessibility.Semantic")}</p>
          </li>
          <li>
            <p>{t("Accessibility.Text")}</p>
          </li>
        </ul>
        <h2>{t("Accessibility.Contents")}</h2>
        <ul>
          <li>
            <p>{t("Accessibility.Tech")}</p>
          </li>
          <li>
            <p>{t("Accessibility.Usable")}</p>
          </li>
        </ul>
        <h2>{t("Accessibility.Images")}</h2>
        <p>{t("Accessibility.Alt")}</p>
        <h2>{t("Accessibility.Tab")}</h2>
        <p>{t("Accessibility.Keyboard")}</p>
        <h2>{t("Accessibility.Audio")}</h2>
        <p>{t("Accessibility.Audio1")}</p>
        <p>{t("Accessibility.Audio2")}</p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
