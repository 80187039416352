import React from "react";
import LogoNavbar from "../functionality/LogoNavbar";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
import "./UserInformation.css";
import edit from "../../images/action/edit_image.svg";
import back from "../../images/navigation/arrow_back_grey.svg";
import eye from "../../images/content/visibility_24px.svg";
import line from "../../images/content/Line.svg";
import { Link } from "react-router-dom";
import perfil from "../../images/perfil.svg";
import i18n from "../translation/i18n";

class UserInformation extends React.Component {
  constructor(props) {
    super(props);
    this.changeName = this.changeName.bind(this);
    this.changeLastname = this.changeLastname.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changeBirth = this.changeBirth.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changeFormation = this.changeFormation.bind(this);
    this.changeInvestigation = this.changeInvestigation.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.changeOldPassword = this.changeOldPassword.bind(this);
    this.changeNewPassword1 = this.changeNewPassword1.bind(this);
    this.changeNewPassword2 = this.changeNewPassword2.bind(this);
    this.changeData = this.changeData.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeImageProfile = this.changeImageProfile.bind(this);
    this.update_token = this.update_token.bind(this);
    this.fillAcademic = this.fillAcademic.bind(this);
    this.fillOccupation = this.fillOccupation.bind(this);

    this.state = {
      currentUser: null,
      profile_image: null,
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      birth: "",
      city: "",
      occupation: "",
      occupation_value: "",
      academic_level: "",
      academic_level_value: "",
      use_formation: false,
      use_investigation: false,
      old_password: "",
      new_password1: "",
      new_password2: "",
      token: "",
      refresh: "",
      pk: "",
      error: null,
      // resp: null,
    };
  }

  componentDidMount() {
    this.update_token();
    const refresh = localStorage.getItem("refresh");
    this.setState({ token: refresh });

    const config2 = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + refresh,
      },
    };

    axios
      .get(
        "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/users",
        config2
      )
      .then(res => {
        const currentUser = res.data;
        this.setState({ currentUser });
        this.setState({ profile_image: currentUser.profile_image });
        this.setState({ first_name: currentUser.name });
        this.setState({ last_name: currentUser.lastname });
        this.setState({ email: currentUser.email });
        this.setState({ city: currentUser.city });
        this.setState({ pk: currentUser.id });
        this.setState({ birth: currentUser.birth });

        if (currentUser.birth) {
          const birth = currentUser.birth;
          const [day, month, year] = birth.split("/");
          this.setState({
            birth: `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`,
          });
        } else {
          this.setState({ birth: "" });
        }

        this.setState({ refresh: this.update_token() });
        if (currentUser.use_formation) {
          document.getElementById("use_formation").checked = true;
          this.setState({ use_formation: true });
        }
        if (currentUser.use_investigation) {
          document.getElementById("use_investigation").checked = true;
          this.setState({ use_investigation: true });
        }
        if (currentUser.occupation === "ST") {
          this.setState({ occupation: "Estudiante" });
        } else if (currentUser.occupation === "TC") {
          this.setState({ occupation: "Profesor" });
        } else if (currentUser.occupation === "EJ") {
          this.setState({ occupation: "Desempleado" });
        } else {
          this.setState({ occupation: "-----" });
        }
        if (currentUser.academic_level === "UN") {
          this.setState({ academic_level: "Grado Universitario" });
        } else if (currentUser.academic_level === "DC") {
          this.setState({ academic_level: "Doctorado" });
        } else if (currentUser.academic_level === "ES") {
          this.setState({ academic_level: "ESO" });
        } else if (currentUser.academic_level === "BH") {
          this.setState({ academic_level: "Bachillerato" });
        } else if (currentUser.academic_level === "FP") {
          this.setState({ academic_level: "Módulo" });
        } else {
          this.setState({ academic_level: "-----" });
        }
        this.setState({ occupation_value: currentUser.occupation });
        this.setState({
          academic_level_value: currentUser.academic_level,
        });
      })
      .catch(err => {
        alert("Ha ocurrido un error");
      });
  }

  async update_token() {
    const refresh = localStorage.getItem("refresh");

    if (!refresh) {
      document.location.replace(
        "https://itinerariosonline.museobilbao.com/login"
      );
    }

    // const body = {
    //   refresh: localStorage,
    // };
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //   })
    //   .catch(err => {
    //     swal(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     document.location.replace(
    //       "https://itinerariosonline.museobilbao.com/login"
    //     );
    //   });
  }

  async changeData(e) {
    e.preventDefault();
    await this.update_token();

    var al = "";
    if (
      this.state.academic_level === document.getElementById("academic").value
    ) {
      al = this.state.academic_level_value;
    } else {
      al = document.getElementById("academic").value;
    }
    var oc = "";
    if (this.state.occupation === document.getElementById("occupation").value) {
      oc = this.state.occupation_value;
    } else {
      oc = document.getElementById("occupation").value;
    }

    const body = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      academic_level: al,

      birth: (() => {
        if (!this.state.birth) return "";
        const [year, month, day] = this.state.birth.split("-");
        return `${day}/${month}/${year}`;
      })(),
      city: this.state.city,
      occupation: oc,
      use_formation: this.state.use_formation,
      use_investigation: this.state.use_investigation,
    };

    const config = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    };
    axios
      .put(
        `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/users`,
        body,
        config
      )
      .then(res => {
        // this.setState({ resp: res.data });
        axios
          .get(
            "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/users",
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + this.state.token,
              },
            }
          )
          .then(res_get => {
            const currentUser = res.data;
            this.setState({ currentUser });
            // AuthService.refreshCurrentUser(res_get.data);
            swal("Se ha actualizado el usuario");
          });
      })
      .catch(err => {
        swal(
          "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
        );
        //document.location.replace("https://itinerariosonline.museobilbao.com/login");
      });
  }

  handleImageChange(e) {
    e.preventDefault();
    this.setState({
      image: e.target.files[0],
    });
    var img = document.getElementById("image");
    img.src = URL.createObjectURL(e.target.files[0]);
    this.setState({
      profile_image: img.src,
    });
  }

  changeName(e) {
    this.setState({
      first_name: e.target.value,
    });
  }

  changeLastname(e) {
    this.setState({
      last_name: e.target.value,
    });
  }

  changeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  changeCity(e) {
    this.setState({
      city: e.target.value,
    });
  }

  changeBirth(e) {
    this.setState({
      birth: e.target.value,
    });
  }

  changeOldPassword(e) {
    this.setState({
      old_password: e.target.value,
    });
  }

  changeNewPassword1(e) {
    this.setState({
      new_password1: e.target.value,
    });
  }

  changeNewPassword2(e) {
    this.setState({
      new_password2: e.target.value,
    });
  }

  changeFormation() {
    var check = document.getElementById("use_formation").checked;
    this.setState({
      use_formation: check,
    });
  }

  changeInvestigation() {
    var check = document.getElementById("use_investigation").checked;
    this.setState({
      use_investigation: check,
    });
  }

  fillOccupation(e) {
    e.preventDefault();
    var options = document.querySelectorAll("#occupation option");
    options.forEach(o => o.remove());

    if (i18n.language == "en") {
      var OCCUPATION_CHOICES = ["-----", "Student", "Teacher", "Unemployed"];
    } else if (i18n.language == "es") {
      var OCCUPATION_CHOICES = [
        "-----",
        "Estudiante",
        "Profesor",
        "Desempleado",
      ];
    } else {
      var OCCUPATION_CHOICES = ["-----", "Ikaslea", "Irakaslea", "Langabetuak"];
    }

    var OCUPATION_VALUES = ["", "ST", "TC", "EJ"];

    var i;
    for (i = 0; i < OCCUPATION_CHOICES.length; i++) {
      var z = document.createElement("option");
      z.setAttribute("value", OCUPATION_VALUES[i]);
      if (OCUPATION_VALUES[i] === this.state.currentUser.occupation) {
        z.setAttribute("selected", true);
      }
      var t = document.createTextNode(OCCUPATION_CHOICES[i]);
      z.appendChild(t);
      document.getElementById("occupation").appendChild(z);
    }
  }

  fillAcademic(e) {
    e.preventDefault();
    var options = document.querySelectorAll("#academic option");
    options.forEach(o => o.remove());

    if (i18n.language == "en") {
      var ACADEMIC_CHOICES = [
        "----",
        "University degree",
        "Doctor`s degree",
        "Institute",
        "High School",
        "Module",
      ];
    } else if (i18n.language == "es") {
      var ACADEMIC_CHOICES = [
        "----",
        "Grado Universitario",
        "Doctorado",
        "ESO",
        "Bachillerato",
        "Módulo",
      ];
    } else {
      var ACADEMIC_CHOICES = [
        "----",
        "Unibertsitateko titulazioa",
        "Doktoregoa",
        "DBH",
        "Institutua",
        "Modulua",
      ];
    }

    var ACADEMIC_VALUES = ["", "UN", "DC", "ES", "BH", "FP"];

    var i;
    for (i = 0; i < ACADEMIC_CHOICES.length; i++) {
      var z = document.createElement("option");
      z.setAttribute("value", ACADEMIC_VALUES[i]);
      if (ACADEMIC_VALUES[i] === this.state.currentUser.academic_level) {
        z.setAttribute("selected", true);
      }
      var t = document.createTextNode(ACADEMIC_CHOICES[i]);
      z.appendChild(t);
      document.getElementById("academic").appendChild(z);
    }
  }

  async changePassword(e) {
    e.preventDefault();
    await this.update_token();
    if (this.state.new_password1 === this.state.new_password2) {
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.state.token, //localStorage.getItem('id_token')
        },
      };

      const body = {
        password: this.state.new_password1,
        password2: this.state.new_password2,
        old_password: this.state.old_password,
      };
      try {
        await axios.put(
          "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/users/password",
          body,
          config
        );
        swal("Contraseña Cambiada Satisfatoriamente");
        this.setState({ new_password1: "" });
        this.setState({ new_password2: "" });
        this.setState({ old_password: "" });
      } catch {
        if (i18n.language == "en") {
          swal("The password could not be changed");
        } else if (i18n.language == "es") {
          swal("No se ha podido cambiar la contraseña");
        } else {
          swal("Ezin izan da pasahitza aldatu");
        }
      }
    } else {
      if (i18n.language == "en") {
        swal("New passwords do not match, check them");
      } else if (i18n.language == "es") {
        swal("Las contraseñas nuevas no coinciden, verifíquelas");
      } else {
        swal("Pasahitz berriak ez datoz bat, egiaztatu itzazu");
      }
    }
  }

  async changeImageProfile(e) {
    e.preventDefault();
    await this.update_token();
    let form_data = new FormData();
    form_data.append("email", this.state.email);
    try {
      form_data.append(
        "profile_image",
        this.state.image,
        this.state.image.name
      );
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + this.state.token,
        },
      };

      let url = `https://backend.itinerariosonline.bilbaomuseoa.eus/update_profile_image/${this.state.pk}/`;
      await axios
        .put(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then(res => {
          axios
            .get(
              "https://backend.itinerariosonline.bilbaomuseoa.eus/users/",
              config
            )
            .then(res_get => {
              AuthService.refreshCurrentUser(res_get.data);
            });
        })
        .catch(err => console.log(err));
      if (i18n.language == "en") {
        swal("User photo successfully changed");
      } else if (i18n.language == "es") {
        swal("Foto de Usuario cambiada satisfactoriamente");
      } else {
        swal("Erabiltzailearen argazkia behar bezala aldatu da");
      }
    } catch {
      if (i18n.language == "en") {
        swal("Photo has not been changed");
      } else if (i18n.language == "es") {
        swal("No se ha cambiado la foto");
      } else {
        swal("Argazkia ez da aldatu");
      }
    }
  }

  handleClick() {
    document.getElementById("image").click();
  }

  logoutUser() {
    localStorage.clear();
    window.location.href = "/";
  }

  render() {
    const { t, i18n } = this.props;
    const { currentUser } = this.state;
    var profile_image = null;
    if (this.state.profile_image) {
      profile_image = this.state.profile_image;
    } else {
      profile_image = perfil;
    }

    return (
      <div className="Home-component">
        <LogoNavbar />
        <div className="back">
          <Link to="/">
            <img src={back} title="Atrás" alt="Atrás" id="atras" />
          </Link>
          <label>{t("user.home")}</label>
        </div>
        <div className="line-div">
          <div className="menu-line">
            <Link to="/user-likes">
              <label>{t("user.like")}</label>
            </Link>
            <Link to="/user-collections">
              <label>{t("user.collections")}</label>
            </Link>
            <label id="profile">{t("user.profile")}</label>
          </div>
          <img
            src={line}
            alt="Linea"
            title="Linea"
            style={{ width: "110%", marginLeft: "-8%" }}
          />
        </div>
        <button
          className="logout-button"
          type="submit"
          onClick={this.logoutUser}
        >
          {t("user.close")}
        </button>
        <div className="name-div">
          <form className="image-form" id="image-form">
            <div className="profile-image">
              <div className="profile-image-div">
                <img id="profile-image" src={profile_image}></img>
                <p>
                  <input
                    type="file"
                    id="image"
                    accept="image/png, image/jpeg"
                    onChange={this.handleImageChange}
                    style={{ display: "none" }}
                    required
                  />
                </p>
              </div>
              <div className="upload-button-div">
                <img id="edit-image" src={edit} onClick={this.handleClick} />
              </div>
            </div>
            <button
              id="image-submit"
              type="button"
              value="Submit"
              onClick={this.changeImageProfile}
            >
              {t("user.update image")}
            </button>
          </form>
          <form className="name-form" onSubmit={this.changeData}>
            <h4>{t("user.account")}</h4>
            <div className="name">
              <div className="tuple">
                <label>{t("user.name")}</label>
                <input
                  value={this.state.first_name}
                  type="input"
                  name="first_name"
                  onChange={this.changeName}
                />
              </div>
              <div className="tuple">
                <label>{t("user.surname")}</label>
                <input
                  value={this.state.last_name}
                  type="input"
                  name="last_name"
                  onChange={this.changeLastname}
                />
              </div>
            </div>
            <div className="email">
              <label>{t("user.email")}</label>
              <input
                value={this.state.email}
                type="email"
                name="email"
                onChange={this.changeEmail}
              />
            </div>
            <button type="submit" value="Submit">
              {t("user.update")}
            </button>
          </form>
        </div>
        <div className="name-div">
          <form className="name-form" onSubmit={this.changeData}>
            <h4>{t("user.info")}</h4>
            <div className="name">
              <div className="tuple">
                <label>{t("user.birth")}</label>
                <input
                  type="date"
                  id="birth"
                  value={this.state.birth}
                  onChange={this.changeBirth}
                />
              </div>
              <div className="tuple">
                <label>{t("user.origin")}</label>
                <input
                  value={this.state.city}
                  type="input"
                  name="city"
                  onChange={this.changeCity}
                />
              </div>
            </div>
            <div className="name">
              <div className="tuple">
                <label>{t("user.occupation")}</label>
                <select id="occupation" onClick={this.fillOccupation}>
                  <option value={this.state.occupation}>
                    {this.state.occupation}
                  </option>
                </select>
              </div>
              <div className="tuple">
                <label>{t("user.academic")}</label>
                <select id="academic" onClick={this.fillAcademic}>
                  <option value={this.state.academic_level}>
                    {this.state.academic_level}
                  </option>
                </select>
              </div>
            </div>
            <div className="use">
              <label>{t("user.what")}</label>
              <div className="checkbox-group">
                <div className="checkbox-div">
                  <input
                    className="checkbox"
                    type="checkbox"
                    name="use_formation"
                    id="use_formation"
                    onChange={this.changeFormation}
                  />
                  <label>{t("user.training")}</label>
                </div>
                <div className="checkbox-div">
                  <input
                    className="checkbox"
                    type="checkbox"
                    name="use_investigation"
                    id="use_investigation"
                    onChange={this.changeInvestigation}
                  />
                  <label>{t("user.investigation")}</label>
                </div>
              </div>
            </div>

            <button type="submit" value="Submit">
              {t("user.update")}
            </button>
          </form>
        </div>
        <div className="name-div">
          <form
            className="name-form"
            id="formElem"
            onSubmit={this.changePassword}
          >
            <h4>{t("user.change")}</h4>
            <div className="email">
              <div className="tuple-email">
                <label>{t("user.pass")}</label>
                <input
                  value={this.state.old_password}
                  type="password"
                  name="old_password"
                  onChange={this.changeOldPassword}
                  required={true}
                />
              </div>
              <div className="tuple-email">
                <label>{t("user.new")}</label>
                <input
                  value={this.state.new_password1}
                  type="password"
                  name="new_password1"
                  onChange={this.changeNewPassword1}
                  required={true}
                />
              </div>
              <div className="tuple-email">
                <label>{t("user.confirm")}</label>
                <input
                  value={this.state.new_password2}
                  type="password"
                  name="new_password2"
                  onChange={this.changeNewPassword2}
                  required={true}
                />
              </div>
            </div>
            <button type="submit" value="Submit">
              {t("user.update")}
            </button>
          </form>
        </div>
        <div>{this.state.error}</div>
      </div>
    );
  }
}

export default UserInformation;
