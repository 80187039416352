import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locale/en/translation.json";
import translationES from "./locale/es/translation.json";
import translationEUS from "./locale/eus/translation.json";

const fallbackLng = ["es"];

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  eus: {
    translation: translationEUS,
  },
};

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng,
    resources,
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
  });

export default i18n;
