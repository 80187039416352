import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GoogleSocialAuth from "./GoogleSocialAuth";
import FacebookSocialAuth from "./FacebookSocialAuth";
import axios from "axios";

// Import Styles
import "./Signup.css";

// Import Components
import LogoNavbarLogin from "./LogoNavbarLogin";
import Navbar from "./Navbar";

// Import Images
import logoImg from "../../images/logo.svg";
import { getErrorList } from "../../utils";

function Signup() {
  const registerText =
    "Regístrate gratis para disfrutar de la experiencia completa";
  const [resp, changeResponse] = useState(null);
  const [respok, changeResponseok] = useState(null);
  const [email, changeEmail] = useState("");
  const [password1, changePassword1] = useState("");
  const [password2, changePassword2] = useState("");
  const [errorMessage, changeMessage] = useState("");
  const [signUpDone, doneSignUp] = useState("");

  const [t, i18n] = useTranslation();

  async function onSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      // const email = "g.urrutia@solvent.es";
      // const password = "MiClave";
      const { data } = await axios.post(
        "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/users/register",
        {
          email,
          password: password1,
          device_name: "Mi Phone",
        },
        config
      );
      changeMessage("");
      localStorage.setItem("refresh", data);
      localStorage.setItem("id_token", data);
      localStorage.setItem("user", JSON.stringify({ email: "test@test.com" }));
      alert("Ha sido registrado satisfactoriamente.");
      doneSignUp("El usuario se ha registrado.");
      changeResponseok(data);
    } catch (error) {
      const errors = getErrorList(error);
      changeMessage(errors);
    }
  }

  function checkboxCheck(e) {
    var checker = document.getElementById("checkme");
    var sendbtn = document.getElementById("sendLogin");
    if (checker.checked) {
      sendbtn.disabled = false;
    } else {
      sendbtn.disabled = true;
    }
  }

  return (
    <div className="Signup">
      <Navbar />
      <Link to="/">
        <LogoNavbarLogin text={registerText} />
      </Link>
      <div className="form-box">
        <form onSubmit={onSubmit}>
          <p className="line-height">{t("signup.Signup with mail")}</p>
          <input
            className="sticky-form"
            onChange={e => changeEmail(e.target.value)}
            value={email}
            type={"email"}
            name={"email"}
            placeholder={t("signup.Email")}
          />
          <input
            className="sticky-form"
            onChange={e => changePassword1(e.target.value)}
            value={password1}
            type={"password"}
            name={"password1"}
            placeholder={t("signup.Password")}
          />
          <input
            className="sticky-form"
            onChange={e => changePassword2(e.target.value)}
            value={password2}
            type={"password"}
            name={"password2"}
            placeholder={t("signup.Password confirm")}
          />
          {errorMessage && <p className="error"> {errorMessage} </p>}
          <div className="check">
            <input
              className="sticky-form"
              type="checkbox"
              id="checkme"
              onChange={e => checkboxCheck(e.target)}
            />
            <p>
              {t("signup.Accept")}
              <a href="#">{t("signup.Terms and conditions")}&nbsp;</a>
              {t("signup.and")}
              <a href="#">&nbsp;{t("signup.Privacy politics")}</a>
            </p>
          </div>
          <button
            type={"submit"}
            name="sendLogin"
            id="sendLogin"
            disabled="disabled"
          >
            {t("signup.Sign up")}
          </button>
        </form>
        <div className="social-login-box">
          <p>{t("login.Login with")}</p>
          <div className="login-button-box">
            <GoogleSocialAuth
              id="google-button"
              className="social-login-button"
            />
            <FacebookSocialAuth
              id="facebook-button"
              className="social-login-button"
            />
          </div>
          <Link to="/login">
            <p className="account-already">{t("signup.Account")}</p>
          </Link>
        </div>
        <div>
          {respok && <Redirect to="/login" />}
          {signUpDone && <Redirect to="/login" />}
        </div>
      </div>
    </div>
  );
}

export default Signup;
