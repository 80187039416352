import React from "react";
import { Link } from "react-router-dom";

import "./LogoNavbar.css";
// import Logo from "../../images/logo.svg";
import bbk from "../../images/BBK-logo-blanco.png";
import logo from "../../images/bilbaomuseoa-logo.png";
import signature from "../../images/bilbaomuseoa-firma.png";

import { useTranslation } from "react-i18next";

function LogoNavbar() {
  const [t, i18n] = useTranslation();
  return (
    <div className="LogoNavbar">
      <Link to="/">
        <img src={logo} title="BBK Logo" alt="BBK Logo" width="300" />
        <div className="itinearios-title-container">
          <h1>{t("navbar.Itinerary")}</h1>
        </div>
      </Link>
      <div className="logo-bbk">
        <img src={signature} title="Museum signature" alt="Museum signature" />
        <img src={bbk} title="BBK Logo" alt="BBK Logo" />
      </div>
    </div>
  );
}

export default LogoNavbar;
