import React from "react";
import LogoNavbarLogin from "../functionality/LogoNavbarLogin";
import AuthService from "../services/auth.service";
import axios from "axios";
import swal from "sweetalert";
//import './UserCollections.css';
import edit from "../../images/action/edit_image.svg";
import back from "../../images/navigation/arrow_back_grey.svg";
import eye from "../../images/content/visibility_24px.svg";
import line from "../../images/content/Line.svg";
import { Link } from "react-router-dom";
import CollectionService from "../services/collection.service";

//https://backend.itinerariosonline.bilbaomuseoa.eus

class ArtworkAddCollection extends React.Component {
  constructor(props) {
    super(props);
    this.update_token = this.update_token.bind(this);
    this.update_collection = this.update_collection.bind(this);
    this.load_collection = this.load_collection.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      collection: null,
      token: "",
      refresh: "",
      pk: "",
      error: null,
      resp: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
    this.setState({ refresh: this.update_token() });
    this.update_collection();
    this.load_collection();
  }

  async update_token() {
    const body = {
      refresh: localStorage.getItem("refresh"),
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    await axios
      .post("/token/refresh/", body, config)
      .then(res => {
        this.setState({ token: res.data.access });
      })
      .catch(err => {
        swal(
          "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
        );
        document.location.replace(
          "https://itinerariosonline.museobilbao.com/login"
        );
      });
  }

  async update_collection() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    };

    await axios
      .get(
        "https://backend.itinerariosonline.bilbaomuseoa.eus/collections/",
        config
      )
      .then(res => {
        CollectionService.refreshCurrentCollection(res.data);
      })
      .catch(err => {
        swal("Ha ocurrido un error");
      });
  }

  load_collection() {
    this.setState({ collection: CollectionService.getCurrentCollection() });
  }

  render() {
    return (
      <div className="Home-component">
        <LogoNavbarLogin />
        <div className="back">
          <Link to="/">
            <img src={back} title="Atrás" alt="Atrás" id="atras" />
          </Link>
          <label>Inicio</label>
        </div>
        <div className="line-div">
          <div className="menu-line">
            <Link to="/user-likes">
              <label>Me Gustan</label>
            </Link>
            <label id="collections">Colecciones</label>
            <Link to="/user-information">
              <label>Mi Perfil</label>
            </Link>
          </div>
          <img
            src={line}
            alt="Linea"
            title="Linea"
            style={{ width: "110%", marginLeft: "-8%" }}
          />
        </div>

        <div>{this.state.error}</div>
      </div>
    );
  }
}

export default ArtworkAddCollection;
