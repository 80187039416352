import React from "react";
import axios from "axios";
import OpenSeaDragon, { ControlAnchor } from "openseadragon";
import favorite_empty from "../../images/action/favorite_border_24px.svg";
import swal from "sweetalert";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";
import { Twitter, Facebook, Whatsapp, Telegram } from "react-social-sharing";
import CollectionListMin from "../gallery/CollectionListMin";
import CollectionService from "../services/collection.service";
import Navbar from "../functionality/Navbar";

// Import Images & Icons

import favorite_full from "../../images/action/favorite_24px.svg";
import info from "../../images/action/info_outline_24px.svg";
import add from "../../images/content/add_24px.svg";
import copy from "../../images/action/copy_24px.svg";
import copied from "../../images/action/copy.svg";
import headset from "../../images/hardware/headset_24px.svg";
import headsetChild from "../../images/hardware/headset_child.svg";
import back from "../../images/navigation/arrow_back_24px.svg";
import share from "../../images/social/share_24px.svg";
import hamburguerMenu from "../../images/action/Hamburger_icon_white.svg";
import close from "../../images/action/close_24px.svg";
import whatsapp from "../../images/social/whatsapp.svg";
import twitter from "../../images/social/twitter.svg";
import facebook from "../../images/social/facebook.svg";

import ReactPlayer from "react-player";

//import show_points from '../../../public/openseadragon/mostrar_puntos.png';
//import hide_points from '../../../public/openseadragon/ocultar_puntos.png';

import "./ArtworkDetail.css";
import { Link } from "react-router-dom";
import i18n from "../translation/i18n";
import { getURLSearchParamsValues } from "../../utils";

const styles = {
  display: "flex",
  width: "60px",
  height: "60px",
  justifyContent: "center",
  borderRadius: "45px",
  padding: "0",
  margin: "0",
};

class ArtworkDetail extends React.Component {
  constructor(props) {
    super(props);
    this.pushOver = this.pushOver.bind(this);
    this.update_token = this.update_token.bind(this);
    this.changeFavorite = this.changeFavorite.bind(this);
    this.addFavorite = this.addFavorite.bind(this);
    this.delFavorite = this.delFavorite.bind(this);
    this.showModalCollections = this.showModalCollections.bind(this);
    this.copy = this.copy.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      collection: CollectionService.getCurrentCollection(),
      artwork: [],
      isLoading: false,
      favorite: false,
      like_image: favorite_empty,
      // pk: "",
      error: null,
      // resp: null,
      token: null,
      points_visible: true,
      copied: false,
      likes: null,
    };
    this.closeModalPoint = this.closeModalPoint.bind(this);
  }

  get artworkId() {
    const [artworkId] = this.props.match.params.artwork.split("-");
    return artworkId;
  }

  async getData() {
    const API = `https://backend.itinerariosonline.museobilbao.com:8081/api/artworks/${this.artworkId}/`;

    await axios
      .get(API)
      .then(response => {
        this.setState({ artwork: response.data, isLoading: false });
      })
      .then(() => {
        this.viewer = OpenSeaDragon({
          id: "museo-detail",
          prefixUrl: "/openseadragon/",
          navImages: {
            zoomIn: {
              REST: "zoomin_grouphover.png",
              GROUP: "zoomin_grouphover.png",
              HOVER: "zoomin_hover.png",
              DOWN: "zoomin_pressed.png",
            },
            zoomOut: {
              REST: "zoomout_grouphover.png",
              GROUP: "zoomout_grouphover.png",
              HOVER: "zoomout_hover.png",
              DOWN: "zoomout_pressed.png",
            },
            home: {
              REST: "home_grouphover.png",
              GROUP: "home_grouphover.png",
              HOVER: "home_hover.png",
              DOWN: "home_pressed.png",
            },
            fullpage: {
              REST: "fullpage_grouphover.png",
              GROUP: "fullpage_grouphover.png",
              HOVER: "fullpage_hover.png",
              DOWN: "fullpage_pressed.png",
            },
          },
          zoomInButton: "zoom-in",
          zoomOutButton: "zoom-out",
          fullPageButton: "full-page",
          homeButton: "home",
          visibilityRatio: 1.0,
          animationTime: 0.5,
          blendTime: 0.1,
          constrainDuringPan: true,
          defaultZoomLevel: 0,
          minZoomLevel: 0,
          maxZoomLevel: 10,
          degrees: 0,
          showNavigator: false,
          autoHideControls: false,
          showFullPageControl: true,
          supportsCanvas: true,
          tileSources: {
            Image: {
              xmlns: "http://schemas.microsoft.com/deepzoom/2008",
              Url: `https://backend.itinerariosonline.bilbaomuseoa.eus/media/dzimages/${this.artworkId}/artwork_files/`,
              Format: "png",
              Overlap: "1",
              TileSize: "254",
              Size: {
                Height: this.state.artwork.height,
                Width: this.state.artwork.width,
              },
            },
          },
          width: this.state.artwork.width,
          debugMode: false,
        });
        this.createOverlays();
        //this.viewer.addHandler('open',this.createOverlays());
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  createOverlays() {
    var i;
    for (i = 0; i < this.state.artwork.pois.length; i++) {
      var overlayid = `example-overlay${this.state.artwork.pois[i].id}`;
      var overlayx = this.state.artwork.pois[i].x / this.state.artwork.width;
      var overlayy = this.state.artwork.pois[i].y / this.state.artwork.width;
      var overlaywidth = this.state.artwork.pois[i].width / 1000;
      var overlayheight = this.state.artwork.pois[i].height / 1000;
      //console.log('datos overlayx ' + overlayx);
      //var overlaywidth = this.state.artwork.pois[i].width;
      //var overlayheight = this.state.artwork.pois[i].height;
      //var overlayx = Number((this.state.artwork.pois[i].x)-(overlaywidth/2));
      //var overlayy = Number((this.state.artwork.pois[i].y)-(overlaywidth/2));
      //var coordinates = this.viewer.viewport.imageToViewportRectangle(overlayx,overlayy,overlaywidth,overlayheight);
      var elt = document.createElement("div");
      elt.id = `div${i}`;
      elt.className = "highlight";

      this.viewer.addOverlay({
        element: elt,
        id: overlayid,
        x: overlayx,
        y: overlayy,
        width: overlaywidth,
        height: overlayheight,
        placement: OpenSeaDragon.Placement.CENTER,
        //location: coordinates,
        //placement: OpenSeaDragon.Placement.CENTER,
        //checkResize: false
      });

      elt.addEventListener(
        "click",
        this.showModalPoint.bind(null, this.state.artwork.pois[i])
      );
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.setState({ copied: false });
    this.setState({ currentUser: AuthService.getCurrentUser() });
    try {
      // this.setState({ pk: this.state.currentUser.id });
      this.setState({ email: this.state.currentUser.email });

      const refresh = localStorage.getItem("refresh");
      if (refresh) {
        this.setState({ token: refresh });

        const config2 = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + refresh,
          },
        };

        axios
          .get(
            "https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/likes",
            config2
          )
          .then(res => {
            this.setState({ likes: res.data.likes }, () => {
              try {
                this.state.likes.map(({ id }) => {
                  if (id == this.artworkId) {
                    this.setState({ like_image: favorite_full });
                    this.setState({ favorite: true });
                  }
                });
              } catch {
                this.setState({ like_image: favorite_empty });
                this.setState({ favorite: false });
              }
            });

            console.log(res);
          })
          .catch(err => {
            alert("Ha ocurrido un error");
          });
      }
    } catch {
      console.log("Usuario no logeado");
    }
    this.getData();
    // try {
    //   this.state.currentUser.like.map(({ id }) => {
    //     if (id == this.artworkId) {
    //       this.setState({ like_image: favorite_full });
    //       this.setState({ favorite: true });
    //     }
    //   });
    // } catch {
    //   this.setState({ like_image: favorite_empty });
    //   this.setState({ favorite: false });
    // }

    //this.viewer.addHandler('open', this.createOverlays());
  }

  async update_token() {
    // const body = {
    //   refresh: localStorage.getItem("refresh"),
    // };
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // await axios
    //   .post(
    //     "https://backend.itinerariosonline.bilbaomuseoa.eus/token/refresh/",
    //     body,
    //     config
    //   )
    //   .then(res => {
    //     this.setState({ token: res.data.access });
    //   })
    //   .catch(err => {
    //     swal(
    //       "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
    //     );
    //     document.location.replace(
    //       "https://itinerariosonline.museobilbao.com/login"
    //     );
    //   });
  }

  pushOver() {
    if (this.state.points_visible) {
      this.viewer.clearOverlays();
      this.setState({ points_visible: false });
      document.getElementById("pushOverlay").src =
        "/openseadragon/mostrar_puntos.png";
    } else {
      this.createOverlays();
      //this.viewer.addHandler('load',this.createOverlays());
      this.setState({ points_visible: true });
      document.getElementById("pushOverlay").src =
        "/openseadragon/ocultar_puntos.png";
    }
  }

  async delFavorite() {
    const like = [];
    like[0] = this.artworkId;

    const body = {
      email: this.state.email,
      like: like,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    };
    axios
      .delete(
        `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/likes/${this.artworkId}`,
        config
      )
      .then(res => {
        // this.setState({ resp: res.data });
        this.setState({ like_image: favorite_empty });
        this.setState({ favorite: false });
        axios
          .get(
            "https://backend.itinerariosonline.bilbaomuseoa.eus/users/",
            config
          )
          .then(res_get => {
            AuthService.refreshCurrentUser(res_get.data);
          });
      })
      .catch(err => {
        swal(
          "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
        );
        //document.location.replace("https://itinerariosonline.museobilbao.com/login");
      });
  }

  async addFavorite() {
    const like = [];
    like[0] = this.artworkId;

    // const body = {
    //   email: this.state.email,
    //   like: like,
    // };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    };
    axios
      .post(
        `https://backend.itinerariosonline.bilbaomuseoa.eus:8081/api/likes`,
        { artwork_id: this.artworkId },
        config
      )
      .then(res => {
        // this.setState({ resp: res.data });
        this.setState({ like_image: favorite_full });
        this.setState({ favorite: true });
        // axios
        //   .get(
        //     "https://backend.itinerariosonline.bilbaomuseoa.eus/users/",
        //     config
        //   )
        //   .then(res_get => {
        //     AuthService.refreshCurrentUser(res_get.data);
        //   });
      })
      .catch(err => {
        swal(
          "Ha pasado más de 24 horas desde que se logeó debe hacerlo nuevamente"
        );
        //TO DO: document.location.replace("https://itinerariosonline.museobilbao.com/login");
      });
  }

  async changeFavorite() {
    if (this.state.currentUser) {
      await this.update_token();
      if (this.state.favorite) {
        this.delFavorite();
      } else {
        this.addFavorite();
      }
    } else {
      if (i18n.language == "en") {
        Swal.fire({
          title: "Login or Sing up to continue",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Ok`,
          confirmButtonColor: "#b49449",
          cancelButtonText: `Maybe later`,
          cancelButtonColor: "#777675",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace("/login");
          }
        });
      } else if (i18n.language == "es") {
        Swal.fire({
          title: "Entra o regístrate para continuar",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Vale`,
          confirmButtonColor: "#b49449",
          cancelButtonText: `En otro momento`,
          cancelButtonColor: "#777675",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace("/login");
          }
        });
      } else {
        Swal.fire({
          title: "Saioa hasi edo Kanta zaitez jarraitzeko",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Ados`,
          confirmButtonColor: "#b49449",
          cancelButtonText: `Beste garai batean`,
          cancelButtonColor: "#777675",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace("/login");
          }
        });
      }
    }
  }

  closeModalShare() {
    document.getElementById("social-sharing").style.visibility = "hidden";
    document.getElementById("social-overlay").style.visibility = "hidden";
  }

  showModalShare() {
    document.getElementById("social-sharing").style.visibility = "visible";
    document.getElementById("social-overlay").style.visibility = "visible";
  }

  closeModalListen() {
    document.getElementById("audio-sharing").style.visibility = "hidden";
    document.getElementById("audio-overlay").style.visibility = "hidden";
  }

  showModalListen() {
    document.getElementById("audio-sharing").style.visibility = "visible";
    document.getElementById("audio-overlay").style.visibility = "visible";
  }

  closeModalListenChild() {
    document.getElementById("audio-child-sharing").style.visibility = "hidden";
    document.getElementById("audio-child-overlay").style.visibility = "hidden";
  }

  showModalListenChild() {
    document.getElementById("audio-child-sharing").style.visibility = "visible";
    document.getElementById("audio-child-overlay").style.visibility = "visible";
  }

  closeModalPoint() {
    document.getElementById("point-overlay").style.visibility = "hidden";
    document.getElementById("point-div").style.visibility = "hidden";
    const myImage = document.getElementById("pois-image");
    while (myImage.firstChild) {
      myImage.removeChild(myImage.lastChild);
    }
    const myVideo = document.getElementById("pois-video");
    while (myVideo.firstChild) {
      myVideo.removeChild(myVideo.lastChild);
    }
    this.viewer.viewport.goHome();
  }

  showModalPoint(point) {
    const title = point.translations[i18n.language].title;
    if (title !== "") {
      document.getElementById("pois-title").textContent = title;
    }

    const description = point.translations[i18n.language].description;
    if (description !== "") {
      document.getElementById("pois-description").innerHTML = description;
    }

    if (point.image != null) {
      var img = document.createElement("img");
      img.src = point.image;
      var src = document.getElementById("pois-image");
      src.appendChild(img);
    }
    if (point.video) {
      var video = document.createElement("ReactPlayer");
      video.url = point.video;
      var url = document.getElementById("pois-video");
      url.appendChild(video);
    }
    document.getElementById("point-overlay").style.visibility = "visible";
    document.getElementById("point-div").style.visibility = "visible";
  }

  showModalCollections() {
    if (this.state.currentUser) {
      document.getElementById("collections-overlay").style.visibility =
        "visible";
    } else {
      if (i18n.language == "en") {
        Swal.fire({
          title: "Login or Sing up to continue",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Ok`,
          confirmButtonColor: "#b49449",
          cancelButtonText: `Maybe later`,
          cancelButtonColor: "#777675",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace("/login");
          }
        });
      } else if (i18n.language == "es") {
        Swal.fire({
          title: "Entra o regístrate para continuar",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Vale`,
          confirmButtonColor: "#b49449",
          cancelButtonText: `En otro momento`,
          cancelButtonColor: "#777675",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace("/login");
          }
        });
      } else {
        Swal.fire({
          title: "Saioa hasi edo Kanta zaitez jarraitzeko",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Ados`,
          confirmButtonColor: "#b49449",
          cancelButtonText: `Beste garai batean`,
          cancelButtonColor: "#777675",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.replace("/login");
          }
        });
      }
    }
  }

  closeAddCollection() {
    document.getElementById("collections-overlay").style.visibility = "hidden";
  }

  copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ copied: true });
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "150px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  render() {
    const { isLoading, error, currentUser, collection, artwork } = this.state;
    const [galleryId, isCollection] = getURLSearchParamsValues(
      this.props.location.search,
      ["galleryId", "isCollection"]
    );
    let headsetButton;
    let headsetChildButton;
    let audioPlayer;
    let audioChildPlayer;

    const { t, i18n } = this.props;

    // document.body.style.overflow = "hidden";
    // document.body.style.margin = "0px";
    // document.body.style.height = "100%";

    // Error Condition
    if (error) {
      return <p className="margin-top">{error.message}</p>;
    }

    // Loading Spinner Condition
    if (isLoading) {
      return <p>Loading...</p>;
    }
    // Audioguides
    if (artwork && artwork.audioguides && artwork.audioguides[i18n.language]) {
      headsetButton = (
        <img
          src={headset}
          className="bottom-space"
          title="Audioguía Icono"
          alt="Audioguía Icono"
          id="audio"
          onClick={()=>{this.closeNav(); this.showModalListen();}}
        />
      );
      audioPlayer = (
        <ReactPlayer
          url={artwork.audioguides[i18n.language].url}
          width="400px"
          height="50px"
          playing={false}
          controls={true}
        />
      );
    }else{
      headsetChildButton = (
        <span
          id="audio"
        />
      )
    }

    // Audioguides Child

    if (
      artwork &&
      artwork.audioguides_child &&
      artwork.audioguides_child[i18n.language]
    ) {
      headsetChildButton = (
        <img
          src={headsetChild}
          title="Audioguía Icono"
          alt="Audioguía Icono"
          id="audio"
          onClick={()=>{this.closeNav(); this.showModalListenChild();}}
        />
      );
      audioChildPlayer = (
        <ReactPlayer
          url={artwork.audioguides_child[i18n.language].url}
          width="400px"
          height="50px"
          playing={false}
          controls={true}
        />
      );
    }else{
      headsetChildButton = (
        <span
          id="audio"
        />
      )
    }

    return (
      <div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "60px",
        }}
      >
        <Link className="" to="/"
          style={{
            marginLeft: "5%",
          }}
        >
          <img src={back} title="Atrás" alt="Atrás" id="atras" onClick={()=>{
            // document.body.style.overflow = null;
            // document.body.style.margin = null;
            // document.body.style.height = null;
          }} />
        </Link>

        <img
          src={hamburguerMenu}
          alt="abrir menu"
          id="openHamburger"
          onClick={this.openNav}
          style={{
            marginRight: "5%",
          }}
        />

      </div>

      <div id="mySidenav" class="sidenav">

        <Link
          id="closeHamburger"
        >
          <img
            src={close}
            alt="cerrar menu"
            onClick={this.closeNav}
          />
        </Link>

        <Link
          className="no-hover"
          to={{
            pathname: `/obras/info/${this.props.match.params.artwork}`,
            search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
          }}
        >
          <img
            src={info}
            title="Informaciónm extra"
            alt="Información extra"
            id="info"
          />
        </Link>

        <Link>
          <img
            src={share}
            alt="Compartir"
            id="compartir"
            onClick={()=>{this.closeNav(); this.showModalShare();}}
          />
        </Link>

        <Link>
          <img
            id="favorite-img"
            src={this.state.like_image}
            alt="Favorito"
            onClick={()=>{this.closeNav(); this.changeFavorite();}}
          ></img>
        </Link>

        <Link>
          <img
            src={add}
            alt="Añadir"
            id="plus"
            onClick={()=>{this.closeNav(); this.showModalCollections();}}
          />
        </Link>

        <Link>
          {headsetButton}
        </Link>

        <Link>
          {headsetChildButton}
        </Link>

        <Navbar user={this.state.currentUser} handler={this.handler} menu={true} />

      </div>   

      <div
        style={{
          // height: "90%",
          // minHeight: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="openSeaDragon-box"
          id="museo-detail"
          style={{
            height: "90vh",
            width: "100vw",
          }}
        >
          <div id="show-hide-points"
            // style={{
            //   position: "fixed",
            //   bottom: "0px",
            // }}
          >
            <a>
              <img
                src="/openseadragon/ocultar_puntos.png"
                id="pushOverlay"
                onClick={this.pushOver}
              />
            </a>
            <a id="zoom-in" href="#zoom-in">
              <img src="/openseadragon/zoomin_hover.png" />
            </a>
            <a id="zoom-out" href="#zoom-out">
              <img src="/openseadragon/zoomout_hover.png" />
            </a>
            <a id="home" href="#home">
              <img src="/openseadragon/home_hover.png" />
            </a>
            <a id="full-page" href="#full-page">
              <img src="/openseadragon/fullpage_hover.png" />
            </a>
          </div>

          <div>
            {/* <Navbar user={this.state.currentUser} handler={this.handler} /> */}
            {/* <div id="menuIconos">
              <div id="menuIzquierda">
                <Link className="no-hover" to="/">
                  <img src={back} title="Atrás" alt="Atrás" id="atras" />
                </Link>
                <Link
                  className="no-hover"
                  to={{
                    pathname: `/obras/info/${this.props.match.params.artwork}`,
                    search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
                  }}
                >
                  <img
                    src={info}
                    title="Informaciónm extra"
                    alt="Información extra"
                    id="info"
                  />
                </Link>
                {headsetButton}
                {headsetChildButton}
              </div>
              <div id="menuDerecha">
                <img
                  src={share}
                  alt="Compartir"
                  id="compartir"
                  onClick={this.showModalShare}
                />
                <img
                  id="favorite-img"
                  src={this.state.like_image}
                  alt="Favorito"
                  onClick={this.changeFavorite}
                ></img>
                <img
                  src={add}
                  alt="Añadir"
                  id="plus"
                  onClick={this.showModalCollections}
                />
              </div>
            </div> */}
          </div>
          <div id="social-overlay">
            <div id="social-sharing">
              <div className="close">
                <img
                  src={close}
                  alt="Cerrar"
                  id="Cerrar"
                  onClick={this.closeModalShare}
                />
              </div>
              <div className="social-content">
                <div>
                  <h5>{t("artworkDetail.share")}</h5>
                </div>
                <div className="art-detail">
                  <p>{t("artworkDetail.link")}</p>
                  <div className="copy-div">
                    <input value={window.location.href} readOnly />
                    <button className="copy-btn" onClick={this.copy}>
                      {!this.state.copied ? (
                        <img src={copy} alt="copy" id="copy" />
                      ) : (
                        <img
                          src={copied}
                          alt="copied"
                          id="copied"
                          style={{ width: "25px" }}
                        />
                      )}
                    </button>
                  </div>
                </div>
                <div className="social-icons">
                  <p>{t("artworkDetail.social")}</p>
                  <div className="icons">
                    <Whatsapp
                      style={styles}
                      solid
                      small
                      link={window.location.href}
                    />
                    <Twitter
                      style={styles}
                      solidcircle
                      small
                      link={window.location.href}
                    />
                    <Facebook
                      style={styles}
                      solidcircle
                      small
                      link={window.location.href}
                    />
                    <Telegram
                      style={styles}
                      solidcircle
                      small
                      link={window.location.href}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="audio-overlay">
            <div id="audio-sharing">
              <div className="close-modal">
                <img
                  src={close}
                  alt="Cerrar"
                  id="Cerrar"
                  onClick={this.closeModalListen}
                />
              </div>
              <div className="audio-content">{audioPlayer}</div>
            </div>
          </div>
          <div id="audio-child-overlay">
            <div id="audio-child-sharing">
              <div className="close-modal">
                <img
                  src={close}
                  alt="Cerrar"
                  id="Cerrar"
                  onClick={this.closeModalListenChild}
                />
              </div>
              <div className="audio-content">{audioChildPlayer}</div>
            </div>
          </div>
          <div id="video-overlay">
            <div id="video-sharing">
              <div className="close">
                <img
                  src={close}
                  alt="Cerrar"
                  id="Cerrar"
                  onClick={this.closeModalVideo}
                />
              </div>
              <div className="video-content"></div>
            </div>
          </div>
          <div id="image-overlay">
            <div id="image-sharing">
              <div className="close">
                <img
                  src={close}
                  alt="Cerrar"
                  id="Cerrar"
                  onClick={this.closeModalImage}
                />
              </div>
              <div className="image-content">image</div>
            </div>
          </div>
          <div id="point-overlay">
            <div id="point-div">
              <div className="close">
                <img
                  src={close}
                  alt="Cerrar"
                  id="Cerrar"
                  onClick={this.closeModalPoint}
                />
              </div>
              <div id="point-text">
                <h5 id="pois-title"></h5>
                <div id="pois-image"></div>
                <div id="pois-video"></div>
                <p id="pois-description"></p>
              </div>
            </div>
          </div>
          <div id="collections-overlay">
            <div id="add-collection-div">
              <div className="close">
                <img
                  src={close}
                  alt="Cerrar"
                  id="Cerrar"
                  onClick={this.closeAddCollection}
                />
              </div>
              <h2 className="overlay-title">Selecciona colección</h2>
              <div className="collections-artwork">
                <CollectionListMin art={this.artworkId} />
              </div>
              <div className="add-div">
                <Link
                  to={{
                    pathname: `/createCollection/${this.artworkId}`,
                  }}
                >
                  <div className="add">
                    <img id="add-collection" src={add} />
                    <p className="create-collection">Crear nueva colección</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default ArtworkDetail;
