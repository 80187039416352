import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// Import Images
import close from "../../images/navigation/close_24px.svg";
import description from "../../images/information/info_white_24px.svg";
import composition from "../../images/information/composition_24px.svg";
import gesture from "../../images/information/gesture_24px.svg";
import attach from "../../images/information/attach_file_24px.svg";
import location from "../../images/navigation/location.svg";
import datasheet from "../../images/information/icon-pdf.svg";

// Import Components
import Modal from "../functionality/Modal";
import Navbar from "../functionality/Navbar";

// Import Styles
import "./ArtworkInfo.css";

import i18n from "../translation/i18n";
import { getURLSearchParamsValues } from "../../utils";

class ArtworkInfo extends Component {
  state = {
    artwork: [],
    isLoading: true,
    error: null,
  };

  get artworkId() {
    const [artworkId] = this.props.match.params.artwork.split("-");
    return artworkId;
  }

  async getData() {
    const API = `https://backend.itinerariosonline.museobilbao.com:8081/api/artworks/${this.artworkId}/`;

    await axios
      .get(API)
      .then(response => {
        this.setState({ artwork: response.data, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ isLoading: true });

    this.getData();
  }

  render() {
    const { isLoading, error, artwork } = this.state;
    const { t, i18n } = this.props;
    const [galleryId, isCollection] = getURLSearchParamsValues(
      this.props.location.search,
      ["galleryId", "isCollection"]
    );
    let buttonComposition;
    let buttonCanvas;
    let buttonLink;
    let buttonPDF;

    function ArtworkDescription() {
      if (artwork.description[i18n.language] !== "") {
        return (
          <div
            className="scroll-max-height text-size"
            dangerouslySetInnerHTML={{
              __html: `${artwork.description[i18n.language]?.title || ""}`,
            }}
          ></div>
        );
      }
    }

    function ArtworkCommentary() {
      if (artwork.commentaries[i18n.language] !== "") {
        return (
          <div
            className="text-size"
            dangerouslySetInnerHTML={{
              __html: `${artwork.commentaries[i18n.language]?.title || ""}`,
            }}
          ></div>
        );
      } else {
        return <div style={{ visibility: "hidden" }}></div>;
      }
    }

    function ArtworkAuthor() {
      const descripcion =
        artwork.author.description[i18n.language]?.title || "";
      // if (descripcion !== "") {
        return (
          <div
            className="black-text"
            dangerouslySetInnerHTML={{
              __html: `${descripcion}`,
            }}
          ></div>
        );
      // }
    }

    function ArtworkAuthorInfo() {
      const birthPlace = artwork.author.birth_place[i18n.language]?.title || "";
      const birthDate = artwork.author.birth_date[i18n.language]?.title || "";
      const deathPlace = artwork.author.death_place[i18n.language]?.title || "";
      const deathDate = artwork.author.death_date[i18n.language]?.title || "";
      return (
        <div className="autor-box">
          <h6>
            {birthPlace} {birthDate}
          </h6>
          <h6>
            {deathPlace} {deathDate}
          </h6>
        </div>
      );
    }

    function ArtworkTitle() {
      const size = artwork.size[i18n.language]?.title || "";
      const title = artwork.translations[i18n.language]?.title || "";
      const date = artwork.date[i18n.language]?.title || "";
      const authorName = artwork.author.name[i18n.language]?.title || "";
      const canvas =
        (artwork.canvas.translations &&
          artwork.canvas?.translations[i18n.language]?.title) ||
        "";

      return (
        <div>
          <div className="title-inline">
            <h1>
              {title}
              {date && <span className="title-size">, {date}</span>}
            </h1>
          </div>
          <h4>{authorName}</h4>
          <h6>
            {canvas} {size} cm
          </h6>
        </div>
      );
    }

    // Error Condition
    if (error) {
      return <p style={{ marginTop: "100px" }}>{error.message}</p>;
    }

    // Loading Spinner Condition
    if (isLoading) {
      return <p style={{ marginTop: "100px" }}>Loading...</p>;
    }

    // Artwork Information Empty

    if (
      artwork.composition &&
      artwork.composition[i18n.language]?.title !== ""
    ) {
      buttonComposition = (
        <Link
          to={{
            pathname: `/obras/composicion/${this.props.match.params.artwork}`,
            search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
          }}
        >
          <img
            src={composition}
            title="Composición Obra Arte"
            alt="Composición Obra Arte"
            id="composition"
          />
        </Link>
      );
    }
    const canvasDescription =
      (artwork.canvas.translations &&
        artwork.canvas?.translations[i18n.language]?.description) ||
      "";
    if (canvasDescription) {
      buttonCanvas = (
        <Link
          to={{
            pathname: `/obras/canvas/${this.props.match.params.artwork}`,
            search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
          }}
        >
          <img
            src={gesture}
            title="Trazos Obra Arte"
            alt="Trazos Obra Arte"
            id="gesture"
          />
        </Link>
      );
    }

    if (artwork.link) {
      buttonLink = (
        <a href={artwork.link} target="_blank">
          <img
            src={attach}
            title="Link Obra Arte"
            alt="Link Obra Arte"
            id="link"
          />
        </a>
      );
    }

    if (artwork.datasheet != null) {
      buttonPDF = (
        <a href={artwork.datasheet} target="_blank" download>
          <img
            src={datasheet}
            title="Ficha Técnica Obra Arte"
            alt="Ficha Técnica Obra Arte"
            id="link"
          />
        </a>
      );
    }
    return (
      <div className="ArtworkInfo">
        <Navbar user={this.state.currentUser} handler={this.handler} />
        <div className="ArtworkInfo-closebar">
          <Link
            to={{
              pathname: `/obras/${this.props.match.params.artwork}`,
              search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
            }}
          >
            <img
              src={close}
              title="Cerrar"
              alt="Cerrar Pantalla Información"
              id="cerrar"
            />
          </Link>
        </div>
        <div className="ArtworkInfo-buttons">
          <div id="menu-buttons">
            <div id="nav-buttons">
              <img
                src={description}
                title="Descripción Obra Arte"
                alt="Descripción Obra Arte"
                id="descripcion"
              />
              {buttonComposition}
              {buttonCanvas}
              {buttonLink}
              {buttonPDF}
            </div>
          </div>
        </div>
        <div className="ArtworkInfo-content">
          <div className="ArtworkInfo-title">
            <ArtworkTitle />
            {/* <Link
              to={{
                pathname: `/museum-map/${this.props.match.params.artwork}`,
                search: `?galleryId=${galleryId}&isCollection=${isCollection}`,
              }}
            >
              <img
                src={location}
                title="Localización dentro del museo"
                alt="Localización dentro del museo"
              />
              <h6 className="location-text">
                {t("artworkInfo.See the location in the museum")}
              </h6>
            </Link> */}
          </div>
          <div className="ArtworkInfo-data">
            <div className="ArtworkInfo-image">
              <img src={artwork.image} />
              <div className="ArtworkInfo-autor">
                <h4>{artwork.author.name[i18n.language]?.title || ''}</h4>
                <ArtworkAuthorInfo />
                <ArtworkAuthor className="Artwork-autor-description" />
              </div>
            </div>
            <div className="ArtworkInfo-text">
              <ArtworkDescription />
              <h3>{t("artworkInfo.Commentary")}</h3>
              <ArtworkCommentary />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtworkInfo;
