import React, { Component } from "react";
import Flickity from "react-flickity-component";
import { Link } from "react-router-dom";
import i18n from "../translation/i18n";
import { withRouter } from "react-router-dom";

import "./GalleryList.css";

import "./flickity.css";
import { getArtworkWithUrlFriendlyFormat } from "../../utils";

class GalleryList extends Component {
  flkty = new Flickity();

  getGalleryFilters = () => {
    const {
      location: { search },
    } = this.props;
    return new URLSearchParams(search).get("filterBy");
  };

  state = {
    filters: this.getGalleryFilters(),
    loaded: false,
  };

  componentDidMount = () => {
    // You can register events in componentDidMount hook
    this.flkty.on("ready", () => {
      !this.state.loaded && this.setState({ loaded: true });
    });
    this.flkty.on(
      "dragStart",
      () => (this.flkty.slider.style.pointerEvents = "none")
    );
    this.flkty.on(
      "dragEnd",
      () => (this.flkty.slider.style.pointerEvents = "auto")
    );
  };

  componentDidUpdate() {
    const filters = this.getGalleryFilters();
    if (filters !== this.state.filters) {
      // Muevo el slider a la izquierda para simular movimiento y luego aplico los filtros
      this.flkty.previous(true);
      setTimeout(() => this.setState({ filters }), 500);
    }
  }

  supportsAllFilters = labels => {
    if (!this.state.filters) return true;
    const filtersArray = this.state.filters.split(",").map(e => +e);
    return filtersArray.every(e => labels.includes(e));
  };

  getCssClass = labels => {
    return this.supportsAllFilters(labels)
      ? "artwork-list"
      : "artwork-list Unselected_style";
  };

  sortbyFilter = (a, b) => {
    const labelsArrayA = a.labels.map(({ id }) => id);
    const labelsArrayB = b.labels.map(({ id }) => id);
    return (
      !!this.supportsAllFilters(labelsArrayB) -
      !!this.supportsAllFilters(labelsArrayA)
    );
  };

  render() {
    const { t } = this.props;
    let isCollection = this.props.isCollection;
    const flickityOptions = {
      // lazyload: true,
      draggable: true,
      contain: true,
      groupCells: true,
      cellAlign: "left",
    };

    let time = 0;
    let artworksList = <></>;
    let galleryTitle;

    const getArtworksList = ({
      id,
      artwork_time,
      thumbnail,
      author,
      labels,
      translations = null,
    }) => {
      if (artwork_time != null) {
        time += artwork_time;
      }

      const labelsArray = labels.map(({ id }) => id);

      const [artworkTitle, authorName] = [
        translations[i18n.language].title,
        author.name[i18n.language].title,
      ];

      const artworkUrl = getArtworkWithUrlFriendlyFormat(id, artworkTitle);

      return (
        <Link
          className="hover-artwork"
          key={id}
          t={t}
          to={{
            pathname: `/obras/${artworkUrl}`,
            search: `?galleryId=${this.props.id}&isCollection=${isCollection}`,
          }}
        >
          <div
            key={id}
            name={labelsArray}
            className={this.getCssClass(labelsArray)}
          >
            <img title={artworkTitle} alt={artworkTitle} src={thumbnail} />
            <h4>{authorName}</h4>
            <h3>{artworkTitle}</h3>
          </div>
        </Link>
      );
    };

    if (this.props.title !== undefined) {
      galleryTitle = this.props.title;
    }

    if (this.props.artworks !== undefined) {
      artworksList = this.props.artworks
        .map(artwork => artwork)
        .sort(this.sortbyFilter)
        .map(getArtworksList);
    }

    if (this.props.user_artworks !== undefined) {
      artworksList = this.props.user_artworks
        .sort(this.sortbyFilter)
        .map(getArtworksList);
    }

    return (
      <div className="GalleryList">
        <h2>{galleryTitle}</h2>
        <div className="gallery-label-list">
          <p>{time} mins.</p>
        </div>
        <div className="artworks-container">
          <Flickity
            flickityRef={c => (this.flkty = c)}
            options={flickityOptions}
            className={
              this.state.loaded ? "carousel fade-in" : "carousel fade-out"
            }
            reloadOnUpdate={true}
          >
            {artworksList}
          </Flickity>
        </div>
      </div>
    );
  }
}

export default withRouter(GalleryList);
